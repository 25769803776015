import React, { useState, useEffect,useRef, } from 'react'
import { useGlobal } from './context'
import axios from 'axios'
import { Link,  useNavigate,useLocation } from 'react-router-dom'
import { Preloader } from './preloader';
import { FaSearch ,FaQuestionCircle, FaHeadset, FaCommentDots} from 'react-icons/fa';

import { Vortex } from 'react-loader-spinner';


import { Response } from './lay-out';

import { TopLink } from './subscription';
import { Pitch } from './App';





export const Feedback=()=>{
 const {prefix,error,setApiError,loadingMessage,setLoadingMessage,} = useGlobal()


 const [email,setEmail]=useState('')
 const [subject,setSubject]=useState('')
 const [message,setMessage]=useState('')
const [hasSubmitButtonClicked,setHasSubmitButtonClicked]= useState(false)

const [isSpinning,setIsSpinning] =useState(false)
const type='feedback'
const navigate= useNavigate()

const data={
  email,
  subject,
  message,
  type,
}

const handleSubmit=async()=>{

const array= Object.entries(data).filter(([key,props])=>props==='')

const all= array.map((item)=>item[0]).join(' ')


  if (array.length==1){
    setApiError(true)
    setLoadingMessage(`${all}, can not be empty`)
    return
  }

  if (array.length>=2){
  
    setApiError(true)
    const readjustedString=array.map((item)=>item[0]).slice(0,array.length-1)
const and=["and"]
const last=array.map((item)=>item[0]).slice(-1)
    const newArray=readjustedString.concat(and,last).join(', ')
   
    setLoadingMessage(`${newArray}, can not be empty`)
    return
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
setIsSpinning(true)


try{

  const url=`${prefix}/feedbacks`
  const response = await axios.post(url,data,config); 
  if(response.status===200){


    setApiError(false)
    setLoadingMessage('Your feedback has been recorded successfully. Our support team will review it shortly. Thank you for your submission.');
    setEmail('')
    setSubject('')
    setMessage('')

  }


}


catch(error){
  setApiError(true)
  if(error.response){
    setLoadingMessage(error.response.data)
    return
  }
  setLoadingMessage('error processing request, please  try again')
}

finally{
  setIsSpinning(false)
}

  
 
}






 return(
  <>
 <TopLink></TopLink>
 




<section className='feed-back-container'>


<section  className='feedback-content'>
  


<h1>Give us a feedback?</h1>
<p>your suggestion are highly welcomed</p>


   <section className='feedback-main-content'>
   


<div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Email *</label>
    <input type="text" name='user-id' className="form-control  signup-form" value={email} onChange={(e) => setEmail(e.target.value)}/>
   <br />
</div>
<div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Subject *</label>
    <input type="text" name='user-id' className="form-control  signup-form" value={subject} onChange={(e) => setSubject(e.target.value)}/>
   <br />
</div>

<div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Description*</label>
    
    <textarea rows="4" cols="50" className="form-control  signup-form" type='text' style={{height:'150px'}} value={message}   onChange={(e) => setMessage(e.target.value)} ></textarea>
   <br />
</div>








<div className='input-holder mobile-holder' >


<div   className='btn-container'   style={{display:'flex',justifyContent:'center', width:""}}>
    <button className='btn btn-primary submit-btn'  type='submit' onClick={handleSubmit}>
    {
    isSpinning ? (
      <>
      
    <Vortex
  visible={true}
  height="40"
  width="40"
  ariaLabel="vortex-loading"
  wrapperStyle={{}}
  wrapperClass="vortex-wrapper"
  colors={['white', 'white', 'white', 'white', 'white', 'white']}
  /> 
      
      </>
    ):(
      <>
    Submit
      </>
    )
   }
    
    
    
    
    </button>
    </div>
   {
    loadingMessage!=='' && (
      <>

      <Response></Response>
      
      </>
    )
   }
 
</div>





   </section>
  
   
   
  










</section>




    
   




























</section>



<section style={{width:"100%",height:"auto"}}>
 <Pitch></Pitch>


</section>


  
 


    
  
  
  </>
 )
}