import React, { useState, useEffect,useRef, } from 'react'

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import {useQuery,} from 'react-query'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Vortex } from 'react-loader-spinner';
import { useGlobal, input } from './context'
import { FaShoppingCart,FaBoxes,FaFolder, FaClock,FaTimes,FaArrowLeft } from 'react-icons/fa'; 
import { faPlus,faTrash,faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LayOut from './lay-out';
import { height } from '@fortawesome/free-solid-svg-icons/fa0';

import { DeleteProduct } from './item-info';
import { Response } from './lay-out';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



import { SemiPreloader,Preloader } from './preloader';






 export  const AddInventory = () => { 

  const {prefix,upb,loadingMessage,setLoadingMessage,resetLoadingText,apiError,setApiError,shopGroup,shopDetails,subType,productToBeDeleted,setIsAdStockShown,setId,setProductUpdate,productUpdate,setIsPreLoaderRunning}=useGlobal()

  

const [expiryDate, setDate]=useState(null)
const [bulkQuantity,setQuantity]=useState('')
const [piecesQuantity,setPquantity]= useState('')
const [bulkPrice,setSellingPriceBulk]= useState('')
const [piecesPrice, setSellingPricePiece]=useState('')
const [costPrice,setCostPrice]= useState('')
const [hasSubmitButtonClicked,setHasSubmitButtonClicked]= useState(false)
const [ doesEmptyFieldExist, setDoesEmptyFieldExist] =useState(false)
const [isConfirmHidden, setIsConfirmHidden]=useState(true)
const [transactionDate,setTransactionDate] =useState(null)
const itemId= localStorage.getItem('product-id')

const [condition,setCondition]= useState('')
const [location,setLocation]=useState('')
const [batch,setBatch]= useState('')
const [seller,setSeller]= useState({name:'',address:'',telephone:""})
const [chatId,setChatId]=useState("")
const [isSpinning,setIsSpinning]=useState(false)

const sourceData={

  expiryDate,
 transactionDate,
 condition,
location,
  
 price:{
  bulkPrice: parseFloat(bulkPrice),

 
 piecesPrice:shopGroup==='group-1'?parseFloat (piecesPrice):parseFloat(bulkPrice),
 },
  costPrice,
 
 
  quantity:(piecesQuantity && bulkQuantity)?  parseFloat(bulkQuantity*upb)+ parseFloat(piecesQuantity):(piecesQuantity&& !bulkQuantity)?parseFloat(piecesQuantity): parseFloat(bulkQuantity* upb),
  batch,
  seller,
 
 
 }





 const  CheckValidation=()=>{
 
  if (shopGroup==='group-1'){

   if( !costPrice || ! piecesPrice || !bulkPrice    ||parseFloat(costPrice)<=0 || parseFloat(piecesPrice)<=0 ||  parseFloat(bulkPrice)<=0   ){

     
  
     setDoesEmptyFieldExist(true)

    }
  
    else{
     
      setDoesEmptyFieldExist(false)
    }

  }


 




  if (shopGroup==='group-2'){

   if( !costPrice  || !bulkPrice  ||parseFloat(costPrice)<=0 ||  parseFloat(bulkPrice)<=0   ){
  
     setDoesEmptyFieldExist(true)
     
    }
  
    else{
     
      setDoesEmptyFieldExist(false)
    }

  }
 
 
 



}


  
  
useEffect(()=>{


  CheckValidation()

 

},[bulkPrice,piecesPrice,bulkQuantity,costPrice,piecesQuantity,])
  
  
const handleConfirm=()=>{
  setLoadingMessage('')




  if (subType==='inactive' || !subType){

   
    return
  }

  


 





  if (!piecesQuantity && !bulkQuantity){
    setApiError(true)
    setDoesEmptyFieldExist(true)
    setLoadingMessage('Quantity can not be empty, please fill up either quantity in bulk or in pieces')


  
    return
  }
  

  if ((piecesQuantity==0 && bulkQuantity==0)||(piecesQuantity==0 && !bulkQuantity)||(bulkQuantity==0 && !piecesQuantity)){
    setApiError(true)
    setDoesEmptyFieldExist(true)
  setLoadingMessage('both pieces quantity and bulk quantity can not be zero or empty')
  
  
  
  return
  }



setHasSubmitButtonClicked(true)




if (doesEmptyFieldExist){
  setLoadingMessage('Please fill up all required information')
  setApiError(true)

  return
}







setIsConfirmHidden(false)



}




const handleSubmit=async()=>{



  //  CheckValidation()



   
    


const data= Object.fromEntries(Object.entries(sourceData).filter(([_,value])=>value!==null && value!=='' && value ))


const lastSeller= Object.fromEntries(Object.entries(data.seller).filter(([_,value])=>value!==null && value!=='' && value ))


data.seller= lastSeller


if (Object.entries(data.seller).length===0){
  delete data.seller
}




    if (transactionDate===null){

      const time= Date.now()
      const newDate= new Date(time)
     
      data.transactionDate=newDate
      
    }
  



  
  
  
  
  
  setHasSubmitButtonClicked(true)
  
  if(doesEmptyFieldExist){
  setApiError(true)
    setLoadingMessage('please fill up all required field')
  
 
    return
  }
  

  
  const config={
   headers:{
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  }
  
  const url= `${prefix}/products/add-inventory/${itemId}`
  
  
  setIsSpinning(true)
  try{

  
   const response= await axios.post(url,data,config)
   setLoadingMessage('Quantity sucessfully added to the inventory')
   setApiError(false)
   setSellingPricePiece('')
  setSellingPriceBulk('')
  setCostPrice('')
  
  setQuantity('')
  setPquantity('')
  setTransactionDate(null)
  setDate(null)
  setDoesEmptyFieldExist(false)
  setSeller({name:'',address:'',telephone:""})
  setProductUpdate(true)
  
  }
  
  
  catch (error){
   setApiError(true)
  
   if (error.request) {
    
    setLoadingMessage('error processing request, please try again')
  
  }
  
  
    else {


      
      setLoadingMessage(error.message)
    }
    
  
  
  }
  
  
  finally{
    setIsSpinning(false)
  }
  
  
  
  }
  




 
 

 
  const handleBack=()=>{
    setIsAdStockShown(false)
    setId("")
  
    if (productUpdate){
      setIsPreLoaderRunning(true)
      window.location.reload()
    }
  }
  
 
   





 
 









 return (
   <>
   
  

{
  !isConfirmHidden && (
    <>
    <section className='register-confirmation-overlay'>


    <div className='confirmation-container'>
      
      <div>
      <FaTimes style={{float:'right',marginRight:"4%",marginTop:"3%",fontSize:"2.4em",cursor:'pointer'}}  onClick={()=>{setIsConfirmHidden(true)}}></FaTimes>
    
      </div>
     

     <p className='font-sub-heading' style={{fontSize:'1.2em',margin:'2%',fontWeight:"bold",color:"#777",textAlign:'center'}}>CONFIRM INFORMATION</p> 


     
     
         <article>
   <p style={{margin:'1% 2%', color:'gray',fontWeight:"500", fontSize:'0.9em',fontWeight:"500", }} className='font-sub-heading'>Cost price<strong  style={{color:"black"}}> :(&#x20A6;){costPrice} </strong>   </p>
  
   <p style={{margin:'1% 2%',fontSize:'0.9em', color:'gray',fontWeight:"500", }} className='font-sub-heading'>Selling price (bulk) <strong  style={{color:"black"}}> :(&#x20A6;){bulkPrice}  </strong>   </p>
  
   {
  shopGroup==='group-1' && (
    <>
    <p style={{margin:'1% 2%', color:'gray',fontWeight:"500", fontSize:'0.9em',fontWeight:"500", }} className='font-sub-heading'>Selling price (pieces) <strong  style={{color:"black"}}> :(&#x20A6;){piecesPrice}</strong>   </p>
    </>
  )
}


    
    

   
     </article>
        
      
    

    
  


    <section >
    
  









   </section>
    
     
   <div style={{display:'flex',justifyContent:'center'}}>
   <button className='btn btn-primary sales-submit-btn font-sub-heading' onClick={handleSubmit} >    
   
  
   
   {
    isSpinning ? (
      <>
      
    <Vortex
  visible={true}
  height="30"
  width="30"
  ariaLabel="vortex-loading"
  wrapperStyle={{}}
  wrapperClass="vortex-wrapper"
  colors={['white', 'white', 'white', 'white', 'white', 'white']}
  /> 
      
      </>
    ):(
      <>
    SUBMIT   
      </>
    )
   }
    
   </button>

   
   </div>



   

  {
    loadingMessage!=='' && (
      <>
      <Response></Response>
      
      </>
    )
  }

  
   </div>

</section>

    
    
    </>
  )
}






<section className=' inventory-margin' >

<div className='inv-name-container'>
 <FaTimes style={{cursor:'pointer',marginRight:"0px"}} size={22} onClick={handleBack}></FaTimes>
 </div>

<div style={{width:'98%'}}>
        <h1 className='font-heading register-main-heading'   style={{fontWeight:'700'}}>Stock Up</h1>

        <div className='font-heading register-main-heading'   style={{fontWeight:'500',fontSize:'1.5rem'}}>{productToBeDeleted[0].toUpperCase() + productToBeDeleted.slice(1)}</div>

        </div>

        <section className='register-whole-container'>


<article className='register-content-container'>

<h5 className='font-sub-heading register-sub-heading special-first-heading' style={{textAlign:''}}>General Information</h5>

<section className='register-info-grid'>


<article className='register-each-partition' >
<div  className='management-register-text'  >Location 
 <div className='indicator-span'> <FontAwesomeIcon  onMouseEnter={()=>{
  setChatId('location')
}}

onMouseLeave={()=>{
  setChatId('')
}}

style={{color:'grey',cursor:'pointer'}} icon={faQuestionCircle} />
{
  chatId=='location' && (
    <>
    <div class="reg-chat-box">
  <div class="reg-pointer"></div>
  <div class="reg-message">The location describes where an item is stored within your shop. Please specify the aisle, shelf, or section for easy retrieval and inventory management.</div>
</div>
    </>
  )
}





 </div> 

</div>





      <input type="text"
className={'management-input form-control important'}


      value={location} 
      
      onChange={(e)=>setLocation(e.target.value)} 
      
      
      />
    
</article>


</section>













<h5 className='font-sub-heading register-sub-heading' style={{textAlign:''}}>Product Specifications</h5>


<section className='register-info-grid'>




<article className='register-each-partition'>
          <div  className='management-register-text'>

{
  shopDetails.industry === 'Provision/Drinks/Pharmaceuticals' && (
    <>
    <div>Batch Number/Lot Number</div>
    </>
  )
}

{
  shopDetails.industry === 'Electronics/Phones/Computers' && (
    <>
    <div>Serial Number/ Lot Number</div>
    </>
  )
}

{
  shopDetails.industry === 'Fashion,Clothing,Accesories and Jewelries' && (
    <>
    <div>Lot Number/ Edition Number</div>
    </>
  )
}


{
  shopDetails.industry === 'Construction and building materials' && (
    <>
    <div>Heat Number/ Production Lot Number</div>
    </>
  )
}

{
  shopDetails.industry === 'Interior Design and Furnitures' && (
    <>
    <div> Lot Number</div>
    </>
  )
}
{
  shopDetails.industry === 'Automobile Parts' && (
    <>
    <div>Part Number/ Serial Number</div>
    </>
  )
}

{
  shopDetails.industry === 'Books and Stationaries' && (
    <>
    <div>Edition Number / Print Run Number</div>
    </>
  )
}



          
<div className='indicator-span'> <FontAwesomeIcon  onMouseEnter={()=>{
  setChatId('batch')
}}

onMouseLeave={()=>{
  setChatId('')
}}

style={{color:'grey',cursor:'pointer'}} icon={faQuestionCircle} />
{
  chatId==='batch' && (
    <>
    <div class="reg-chat-box">
  <div class="reg-pointer"></div>
  <div class="reg-message">This is a unique identifier assigned to a group of products manufactured or processed together.The batch number is usually printed on product packaging, labels, or directly on the product itself</div>
</div>
    </>
  )
}





 </div> 





          
          
          
          
          
          
          </div>
            
            <input type="text"    className={ 'management-input  form-control important'}
             value={batch}  onChange={(e)=>setBatch(e.target.value)} />

            </article>






{
               shopDetails.industry==='Provision/Drinks/Pharmaceuticals' && (
               
               <>

<article className='register-each-partition'>
          <div  className='management-register-text'>Expiry Date
<div className='indicator-span'> <FontAwesomeIcon  onMouseEnter={()=>{
  setChatId('expiry')
}}

onMouseLeave={()=>{
  setChatId('')
}}

style={{color:'grey',cursor:'pointer'}} icon={faQuestionCircle} />
{
  chatId==='expiry' && (
    <>
    <div class="reg-chat-box">
  <div class="reg-pointer"></div>
  <div class="reg-message"> The expiry date indicates the date until which a product remains usable or fit for consumption</div>
</div>
    </>
  )
}





 </div> 
         
          </div>



   
         
   <DatePicker
      selected={expiryDate}
      onChange={(expiryDate) => setDate(expiryDate)}
      dateFormat="dd/MM/yyyy"
      className="management-input form-control important"
      wrapperClassName="date-picker-wrapper" // Custom wrapper class
    />

   
    
             
       

            </article>




               
               
               </>)
            }



</section>




<h5 className='font-sub-heading register-sub-heading' style={{textAlign:''}}>Quantity Information</h5>

<section className='register-info-grid'>

<article className='register-each-partition'  >
<div  className='management-register-text'>{shopGroup!=='group-2'?"Quantity(bulk)":'Quantity*'}






{

shopGroup==='group-2' && (
  <>
  
  <div className='indicator-span'> <FontAwesomeIcon  onMouseEnter={()=>{
  setChatId('qb')
}}

onMouseLeave={()=>{
  setChatId('')
}}

style={{color:'grey',cursor:'pointer'}} icon={faQuestionCircle} />
{
  chatId==='qb' && (
    <>
    <div class="reg-chat-box">
  <div class="reg-pointer"></div>
  <div class="reg-message">
    {
      shopGroup!=='group-2' ?(
        <>
        This is the quantity of item you are adding to the inventory in bulk.  <span> {  shopDetails.industry==='Provision/Drinks/Pharmaceuticals'?"For example,if you are adding 12 crates of coke you can put 12 ":  shopDetails.industry === 'Books and Stationaries'?"For example,if you are adding 5 packs of books you can put 5":"For example,if you are adding 5 bale of shirts  you can put 5"}</span> 
        
        </>
      ):(
        <>
        
        This is the quantity of item you are adding to the inventory 
        </>
      )
    }
    
    
    
    
   
  
  
  
  
  </div>
</div>
    </>
  )
}





 </div> 
  
  
  
  </>
)

}



          












</div>
      <input type="text"  className='management-input  form-control'
        onChange={(e) => {
          const inputValue = e.target.value;
        
          // Allow only digits and a dot
          const sanitizedInput = inputValue.replace(/[^\d.]/g, '');
        
          // Ensure there's at most one dot
          const dotCount = sanitizedInput.split('.').length - 1;
        
          if (dotCount <= 1) {
            // Update state with the sanitized input
            setQuantity(() => sanitizedInput);
          }
        }}
        value={bulkQuantity}
    
      />
<div className='management-commentary' >This field can not be zero or less than zero </div>

</article>


{
        shopGroup!=='group-2'  && (
          <>
        <article className='register-each-partition'>

        <div  className='management-register-text'>Quantity(pieces)
        
        <div className='indicator-span'> <FontAwesomeIcon  onMouseEnter={()=>{
  setChatId('qp')
}}

onMouseLeave={()=>{
  setChatId('')
}}

style={{color:'grey',cursor:'pointer'}} icon={faQuestionCircle} />
{
  chatId==='qp' && (
    <>
    <div class="reg-chat-box">
  <div class="reg-pointer"></div>
  <div class="reg-message">Here, you specify the quantity of items being added to the inventory individually or in smaller units. This is particularly useful when dealing with products that are sold in smaller quantities or as individual items. <span> {  shopDetails.industry==='Provision/Drinks/Pharmaceuticals'?"For example, if 12  bottles of coke make one cartons, and you have 6 bottles which is not up to one full carton, you can put that 6 here  to make up for that":  shopDetails.industry === 'Books and Stationaries'?"For example, if 12  pens make one full pack, and you have 6 pens which is not up to one full pack, you can put that 6 here to make up for that":"For example, if 12  t-shirts   make one full bale, and you have 6 t-shirts which is not up to one full bale, you can put that 6 here to make up for that"}</span>
  
  
  
  
  </div>
</div>
    </>
  )
}





 </div> 
        
        
        
        
        
        </div>
      <input type="text"  className='management-input  form-control'
      
      
      onChange={(e) => {
        const inputValue = e.target.value;
      
        // Allow only digits and a dot
        const sanitizedInput = inputValue.replace(/[^\d.]/g, '');
      
        // Ensure there's at most one dot
        const dotCount = sanitizedInput.split('.').length - 1;
      
        if (dotCount <= 1) {
          // Update state with the sanitized input
          setPquantity(() => sanitizedInput);
        }
      }}
      
      value={piecesQuantity}
    
      
      
      
      />
       
        </article>
      
          </>
        )
      }



</section>
     


<h5 className='font-sub-heading register-sub-heading'>Pricing and Cost Information</h5>

<section className='register-info-grid'>
<article className='register-each-partition'>

<div  className='management-register-text'>Cost Price*

<div className='indicator-span'> <FontAwesomeIcon  onMouseEnter={()=>{
  setChatId('cost')
}}

onMouseLeave={()=>{
  setChatId('')
}}

style={{color:'grey',cursor:'pointer'}} icon={faQuestionCircle} />
{
  chatId==='cost' && (
    <>
    <div class="reg-chat-box">
  <div class="reg-pointer"></div>
  <div class="reg-message">The amount you purchased this item.</div>
</div>
    </>
  )
}





 </div> 
          




</div>
      <input type="text" 
      
      
      
      value={costPrice}
      placeholder={
       hasSubmitButtonClicked && (
         isNaN(parseFloat(costPrice)) || costPrice <= 0 || costPrice === ''
       ) ?
       'Please enter a valid cost price' : ''
     }
     className={
       hasSubmitButtonClicked && (isNaN(parseFloat(costPrice)) || costPrice <= 0 || costPrice === '') ?
       'management-input  form-control important unfilled' : 'management-input  form-control important'
     }
      
      
      
     onChange={(e) => {
      const inputValue = e.target.value;
    
      // Allow only digits and a dot
      const sanitizedInput = inputValue.replace(/[^\d.]/g, '');
    
      // Ensure there's at most one dot
      const dotCount = sanitizedInput.split('.').length - 1;
    
      if (dotCount <= 1) {
        // Update state with the sanitized input
        setCostPrice(() => sanitizedInput);
      }
    }}
        
      
      
      
      
      />
      <div className='management-commentary'>This field can not be zero or less than zero</div>

</article>
<article className='register-each-partition'>
<div  className='management-register-text'>{shopGroup!=='group-2'?"Selling Price(bulk)*":'Selling Price*'}






<div className='indicator-span'> <FontAwesomeIcon  onMouseEnter={()=>{
  setChatId('sq')
}}

onMouseLeave={()=>{
  setChatId('')
}}

style={{color:'grey',cursor:'pointer'}} icon={faQuestionCircle} />
{
  chatId==='sq' && (
    <>
    <div class="reg-chat-box">
  <div class="reg-pointer"></div>
  <div class="reg-message"> 
  
  {
    shopGroup!=='group-2' ? (
      <>

The selling price is the amount at which  you intend  to sell to customers in bulk <span> {  shopDetails.industry==='Provision/Drinks/Pharmaceuticals'?"For example,you can set the price of  one crate  of coke at 3500 naira":  shopDetails.industry === 'Books and Stationaries'?"For example,you can set the price of  one pack  of pencils at 1000 naira":"For example,you can set the price of  one bale of t-shirts at 35000 naira"}</span>
  
  

      </>
    ) :<>
    The selling price is the amount at which  you intend  to sell to customers 
    
    </>

  }
  
  
  
  
  
  </div>
</div>
    </>
  )
}





 </div> 

    
  
   
  











</div>
      <input type="text" 


placeholder={
  hasSubmitButtonClicked && (
    isNaN(parseFloat(piecesPrice)) || piecesPrice <= 0 || piecesPrice === ''
  ) ?
  'Please enter a valid pieces selling price' : ''
}
      
      value={bulkPrice}  
      className={
        hasSubmitButtonClicked && (isNaN(parseFloat(bulkPrice)) || bulkPrice <= 0 || bulkPrice === '') ?
        'management-input form-control important unfilled' : 'management-input form-control important'
      }
      
      onChange={(e) => {
        const inputValue = e.target.value;
      
        // Allow only digits and a dot
        const sanitizedInput = inputValue.replace(/[^\d.]/g, '');
      
        // Ensure there's at most one dot
        const dotCount = sanitizedInput.split('.').length - 1;
      
        if (dotCount <= 1) {
          // Update state with the sanitized input
          setSellingPriceBulk(() => sanitizedInput);
        }
      }}    
      />


</article>



{
   shopGroup!=='group-2'  && (
    <>
    <article className='register-each-partition'>
    <div  className='management-register-text'>Selling Price(pieces)*
    
    <div className='indicator-span'> <FontAwesomeIcon  onMouseEnter={()=>{
  setChatId('sp')
}}

onMouseLeave={()=>{
  setChatId('')
}}

style={{color:'grey',cursor:'pointer'}} icon={faQuestionCircle} />
{
  chatId==='sp' && (
    <>
    <div class="reg-chat-box">
  <div class="reg-pointer"></div>
  <div class="reg-message">The selling price is the amount at which  you intend  to sell to customers in pieces <span> {  shopDetails.industry==='Provision/Drinks/Pharmaceuticals'?"For example,you can set the price of  one bottle of coke at 350 naira":  shopDetails.industry === 'Books and Stationaries'?"For example,you can set the price of  one single pencil at 50 naira":"For example,you can set the price of one t-shirt at 3500 naira"}</span>
  
  
  
  
  </div>
</div>
    </>
  )
}





 </div> 
    
    
    
    </div>
     
     <input type="text"
     
     placeholder={
       hasSubmitButtonClicked && (
         isNaN(parseFloat(piecesPrice)) || piecesPrice <= 0 || piecesPrice === ''
       ) ?
       'Please enter a valid pieces selling price' : ''
     }




     className={
       hasSubmitButtonClicked && (isNaN(parseFloat(piecesPrice)) || piecesPrice <= 0 || piecesPrice === '') ?
       'management-input form-control important unfilled' : 'management-input form-control important'
     }
     value={piecesPrice}  
     
     onChange={(e) => {
       const inputValue = e.target.value;
     
       // Allow only digits and a dot
       const sanitizedInput = inputValue.replace(/[^\d.]/g, '');
     
       // Ensure there's at most one dot
       const dotCount = sanitizedInput.split('.').length - 1;
     
       if (dotCount <= 1) {
         // Update state with the sanitized input
         setSellingPricePiece(() => sanitizedInput);
       }
     }}/>
    
    </article>
  
    </>
   )
}




</section>




<h5 className='font-sub-heading register-sub-heading'>Supplier Details</h5>

<section className='register-info-grid'>
<article className='register-each-partition'>
<div  className='management-register-text'>Supplier Name</div>
      <input type="text"    className={ 'management-input  form-control important'}
       value={seller.name}  onChange={(e)=>  setSeller((prev)=>({...prev,name:e.target.value}))}
    
       />
       </article>


       <article className='register-each-partition'>

       <div  className='management-register-text'>Supplier Address</div>
      <input type="text"    className={ 'management-input  form-control important'}
       value={seller.address}  onChange={(e)=>  setSeller((prev)=>({...prev,address:e.target.value}))}
    
       />

</article>


<article className='register-each-partition'>

<div  className='management-register-text'>Supplier Telephone</div>
      <input type="text"    className={ 'management-input  form-control important'}
       value={seller.telephone}  onChange={(e)=>  setSeller((prev)=>({...prev,telephone:e.target.value}))}
    
       />

</article>





</section>






<h5 className='font-sub-heading register-sub-heading'>Additional Information
</h5>

<section className='register-info-grid'>
  
{
      ( shopDetails.industry === 'Electronics/Phones/Computers' ||shopDetails.industry === 'Fashion,Clothing,Accesories and Jewelries' )  && (
          <>


<article className='register-each-partition'>
<div  className='management-register-text'>Condition</div>
            <select name="" id="" type='text' className='management-input' value={condition} onChange={(e)=>setCondition(e.target.value)}>

            {
  shopDetails.industry === 'Electronics/Phones/Computers' && (
    <>
      <option value=""></option>
      <option value={'New'}>New</option>
      <option value={'Refurbished'}>Refurbished</option>
      <option value={'Used'}>Used</option>


      
    </>
  )
}

{
  shopDetails.industry === 'Fashion,Clothing,Accesories and Jewelries' && (
    <>
      <option value=""></option>
      <option value={'New'}>New</option>
      <option value={'Used'}>Used</option>


      
    </>
  )
}


            </select>
          
  </article>
           
          
          </>
        )
      }
     






</section>


     <h5 className='font-sub-heading register-sub-heading'> Order Information</h5>
     <section className='register-info-grid'> 
     <article className='register-each-partition'>
     <div  className='management-register-text'>Order Receive Date</div>
   <DatePicker 
   
   selected={transactionDate}
   onChange={(transactionDate) => setTransactionDate(transactionDate)}
   dateFormat="dd/MM/yyyy"
   showTimeInput
   className="management-input form-control important"
      wrapperClassName="date-picker-wrapper" 

   
   
   
   
   ></DatePicker>
      <div className='management-commentary' >The system automatically captures the present date as the order received date if left unspecified. However, users have the option to manually select a different date if necessary.</div>


     </article>
  
     
</section>




    










   

<br />

<section className='register-btn-section'>

<button className='btn btn-primary font-text register-btn-submit' style={{marginTop:"8px",}}  onClick={handleConfirm}>Submit</button>

<br />
<br />

{
(doesEmptyFieldExist &&  isConfirmHidden) && (
<>
{
  loadingMessage!=='' && (
    <>
    <Response></Response>
    </>
  )
}


</>
)
}

{
(subType==='inactive' ) && (
<>
<div className={ 'alert alert-danger'  } style={{fontWeight:'700',margin:"2vh 0"}}>
You dont have active subscription
</div> 

</>
)
}


</section>






</article>




     
       </section>


</section>









   
   

  
   </>
 );
};




















const Invetory=()=>{

const {fetchAllProducts,inventoryData,searchInput,         inventoryBatches,setInventoryData, expiryDateData, isSearching, setIsPreLoaderRunning,shopGroup,setProductToBeDeleted,   productToBeDeleted,isAddStockShown,setIsAdStockShown,setId,id,setUpb,isPreLoaderRunning,error,  setProductToBeDeletedID,productUpdate,categories}= useGlobal()

const [sortOption,setSortOption]= useState('')
const [isDeleteShown,setIsDeleteShown]=useState(false)

const [indicator,setIndicator]= useState('')
const [focusId,setFocusId]=useState('')

const navigate= useNavigate()

useEffect(()=>{
 


if(!searchInput || productUpdate){

  fetchAllProducts()

}

 
},[])





const navigateToProductInfo=(e)=>{
  setIsPreLoaderRunning(true)
const data=e.target.dataset.id





  localStorage.setItem('product-id', data);

  navigate('/product-info')

}


const handleAction=(e,action)=>{
  const id= e.currentTarget.dataset.id
  const name= e.currentTarget.dataset.name
  setProductToBeDeleted(name)
  localStorage.setItem('product-id',id);
setId(id)

if(action==='stock'){

  setIsAdStockShown(true)

  
}

else{
  setIsDeleteShown(true)

}


}







const Sort= (e)=>{


setSortOption(e.target.value)

 
}


useEffect(()=>{



  const newInventory=expiryDateData.map((item)=>{
  return{
    ...item,
    totalQuantity:item.inventoryData.reduce((acc,item)=>acc+item.quantity,0)
  }
  })

  if(sortOption==='reset'){
    const reset= newInventory.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
  
    setInventoryData(reset)
  }

 

 
  
  
  if(sortOption==='hq'){
    const highestToLowest= newInventory.sort((a,b)=>(b.totalQuantity/b.upb)-(a.totalQuantity/a.upb))

   
  
    setInventoryData(highestToLowest)
  }

  if(sortOption==='lq'){
    const lowestToHighest= newInventory.sort((a,b)=>(a.totalQuantity/a.upb)-(b.totalQuantity/b.upb))

    setInventoryData(lowestToHighest)
  
  }

  if(sortOption==='id'){


    const lowestToHighest=  newInventory.sort((a, b) =>
    a.sku.toLowerCase().localeCompare(b.sku.toLowerCase())
  );
    setInventoryData(lowestToHighest)
  
  }









  


 
},[sortOption])

const handleNavigate = (e) => {
  e.preventDefault();
  navigate('/inventory/history');
};








if (isPreLoaderRunning){
  return(
    <>
    

    <Preloader></Preloader>
    
    </>
  )
}




if(error==='session expired log in'){
  return (
    <>
  
    <div style={{width:'100vw',height:"100vh",display:'flex',justifyContent:'center',alignItems:'center'}} className='alert'>
    <div style={{marginBottom:"1%"}}>Your session has expired please log in</div>
    <button  className='btn btn-primary retry-btn'  onClick={()=>{navigate('/log-in')}}>click log in</button>
    </div>
  
    </>
  )
}



if(error){
  return (
    <>
  
    <div style={{width:'100vw',height:"100vh",display:'flex',justifyContent:'center',alignItems:'center'}} className='alert'>
    <div style={{marginBottom:"1%"}}>{error}</div>
    <button  className='btn btn-primary retry-btn'  onClick={()=>{window.location.reload()}}>Retry</button>
    </div>
  
    </>
  )
}










if (inventoryData.length===0){
  return(
    <>
    <LayOut>

    <section className=' inventory-content-container' >
    <div className='inventory-first-line'>
      <div style={{ fontSize: '1.5em' }} className='font-heading'>
        Inventory
      </div>
      <button
        className='btn btn-primary stock-record-btn font-sub-heading'
        onClick={handleNavigate}
        
      >
        <FaClock style={{ marginRight: '5px', cursor: 'pointer' }} />
        Past Record
      </button>
    </div>

    <section className='inventory-summary-container'>
      
    <h5>No Product registered yet</h5>

    </section>


      </section>


    </LayOut>
    </>
  )
}






 return(

<>




{
  isDeleteShown && (
    <>
   <section className='inventory-delete-overlay'>

<div className='inventory-delete-container'> 
<div style={{width:'100%'}}>
<FaTimes  style={{float:'right',cursor:'pointer'}}  onClick={()=>{setIsDeleteShown(false);setId("")}} size={24}></FaTimes>
</div>
<DeleteProduct></DeleteProduct>
</div>


</section> 
    
    </>
  )
}




<LayOut>


{
  isDeleteShown && (
    <>
   <section className='mobile-inventory-delete-overlay'>

<div className='inventory-delete-container'> 
<div style={{width:'100%'}}>
<FaTimes  style={{float:'right',cursor:'pointer'}}  onClick={()=>{setIsDeleteShown(false);setId("")}} size={24}></FaTimes>
</div>
<DeleteProduct></DeleteProduct>
</div>


</section> 
    
    </>
  )
}


{
  isAddStockShown && (
    <>
    <AddInventory></AddInventory>
    </>
  )
}














{
  !isAddStockShown && (
    <>
    <main  className='main-inventory-container' style={{paddingBottom:isAddStockShown&&'0px'}}>


<section className=' inventory-content-container' >




<div className='inventory-first-line'>
    <div  className='font-heading inventory-main-heading'>
      Inventory
    </div>
    <button
      className='btn btn-primary stock-record-btn font-sub-heading'
      onClick={handleNavigate}
    >
      <FaClock style={{ marginRight: '5px', cursor: 'pointer' }} />
      Past Record
    </button>
  </div>



<article className='inventory-summary-container-scollable'>

<section className='inventory-summary-container'>



<div className='inventory-summary-header' >



<div className=' col-sm-4 inventory-summary' >
<div className='inventory-summary-inner-holder'>

<div className='inv-logo-holder' style={{backgroundColor:'hsl(277, 70%, 88%)'}}>
<FaShoppingCart className='inventory-logo-indicator' style={{color:'purple'}}></FaShoppingCart>
</div>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

<strong className='quick-numbers'>{inventoryData.length}</strong>
<div className='quick-headings'  >Total Products</div>
</div>
</div>

</div>




<div className=' col-sm-4 inventory-summary' >
<div className='inventory-summary-inner-holder'>

<div className='inv-logo-holder' style={{backgroundColor:'hsl(351, 53%, 93%)'}}>
<FaBoxes className='inventory-logo-indicator' style={{color:'hsl(351, 89%, 67%)'}}></FaBoxes>
</div>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

<strong className='quick-numbers'>{inventoryBatches}</strong>
<div className='quick-headings'  >Total Batches</div>
</div>
</div>

</div>


 
<div className=' col-sm-4 inventory-summary' >
<div className='inventory-summary-inner-holder'>

<div className='inv-logo-holder' style={{backgroundColor:'hsl(38, 86%, 75%)'}}>
<FaFolder className='inventory-logo-indicator' style={{color:'orange'}}></FaFolder>
</div>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

<strong className='quick-numbers'>{categories.length}</strong>
<div className='quick-headings'  >All Categories</div>
</div>
</div>

</div>






</div>
</section>

</article>







<section className='inventory-container-table'>



<article className='sort-export-line'>


<select name="" id="" value={sortOption} className='form-select inventory-select font-sub-heading' style={{width:'auto',}} onChange={Sort}  >
<option value="" className='form-select-option'>Sort</option>
<option value="reset" className='form-select-option'>Reset</option>
<option value="hq" className='form-select-option'>High Quantity</option>
<option value="lq" className='form-select-option'>Low Quantity</option>

<option value="id" className='form-select-option'>Product ID</option>
</select>




<select name="" id="" className='form-select inventory-select font-sub-heading' style={{width:'auto',opacity:"0"}}>

<option value="" className='form-select-option'>Export</option>
<option value="reset" className='form-select-option'>CSV</option>
<option value="hq" className='form-select-option'>XLS 
</option>
<option value="">XLSX </option>
</select>


</article>




{
inventoryData.length!==0 && (
<>
<div className='inventory-table-container'>

<div className='invetory-table-header'>ID</div>
<div className='invetory-table-header'>Name</div>
<div className='invetory-table-header'>Quantity</div>
<div className='invetory-table-header'>Category</div>
<div className='invetory-table-header'>Actions</div>




</div> 



</>
)
}





<div className='inventory-main-container' style={{backgroundColor:"white"}}>


{
inventoryData.map((item,index)=>{
const {_id,name,sku,inventoryData,upb}=item

const number=index+1;
const remainder=number%2


const allQuantity=inventoryData.map((item)=>{
return item.quantity
}).reduce((acc,cv)=>{
return acc + cv
},0)


let minimumExpirydate= inventoryData.length !== 0 ? inventoryData[0].expiryDate : 'N/A';

if(inventoryData.length!==0){

const allExpiryDate= inventoryData.map((item)=>item.expiryDate)




for (let i=0;i<allExpiryDate.length;i++){
const element=allExpiryDate[i]

const parsedElement=Date.parse(element)

const parsedMinimumExpiryDate=Date.parse(minimumExpirydate)





if (parsedElement<parsedMinimumExpiryDate){

 

 minimumExpirydate =element



}
}




}

const bulk= parseFloat( allQuantity/item.upb)


const piecesRemainder=(parseFloat(allQuantity)- parseInt(bulk)*item.upb)










return(

  <>









  
  <div  className='inventory-container'      style={{cursor:'',backgroundColor:remainder!==0?'white':'hsl(0,0%,95%)',}} data-id={_id}  key={index}>

<div className='inventory-properties'     onClick={(navigateToProductInfo)}  data-id={_id}  style={{cursor:'pointer'}}>{sku}</div>
<div  className='inventory-properties' data-id={_id}     style={{cursor:'pointer'}} onClick={(navigateToProductInfo)} >{name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()}</div>

<div className='inventory-properties' data-id={_id}  style={{cursor:'pointer'}} onClick={(navigateToProductInfo)}  >
{piecesRemainder === 0 ? `${parseInt(parseFloat(allQuantity / upb).toFixed(1))} 📦` : (piecesRemainder !== 0 ? `${parseInt(allQuantity / item.upb).toFixed(1)} 📦 ${piecesRemainder.toFixed(1)} 🔵` : "OOS")}
</div>



<div  className='inventory-properties'   style={{cursor:'pointer'}}  onClick={(navigateToProductInfo)}  data-id={_id}>{item.category}</div>
<div  className='inventory-properties' data-id={_id}> 

<div className='action-container'>
<div className='action-btn-indicator-container'>

{

(indicator==='inv' && focusId===_id) && (
  <>
  <div className='inventory-indicator'>
<div className='inventory-indicator-chat'>
Stock up
</div>

<div className='inventory-indicator-pointer'>

</div>

</div>
  
  </>
)

}





<button  className='btn btn-primary inventory-add-btn' 
data-id={_id}  
data-name={name}
onClick={(e)=>{handleAction(e,'stock');setUpb(item.upb)}}
onMouseEnter={()=>{setIndicator('inv');setFocusId(_id)}}  onMouseLeave={()=>{setIndicator('');setFocusId('')}} 


>
<FontAwesomeIcon  icon={faPlus} className='action'  onMouseEnter={()=>{setIndicator('inv');setFocusId(_id)}} />
</button>


</div>







<div className='action-btn-indicator-container'>

{

(indicator==='delete' && focusId===_id) && (
<>
<div className='inventory-indicator'>
<div className='inventory-indicator-chat'>
Delete
</div>

<div className='inventory-indicator-pointer'>

</div>

</div>

</>
)

}

<button          className='btn btn-danger inventory-delete-btn'  onMouseEnter={()=>{setIndicator('delete');setFocusId(_id)}}  onMouseLeave={()=>{setIndicator('');setFocusId('')}}  data-id={_id} data-name={name} onClick={(e)=>{handleAction(e,'delete');setProductToBeDeletedID(item._id)}}>
<FontAwesomeIcon  icon={faTrash}  onMouseEnter={()=>{setIndicator('delete');setFocusId(_id)}}   className='action-delete-btn action' style={{cursor:"pointer"}}></FontAwesomeIcon>

</button>

</div>





</div>

</div>




</div>
  
  </>
)
})
}










</div>




</section>


<section className='mobile-inventory-container-table'>



<article className='sort-export-line'>


<select name="" id="" value={sortOption} className='form-select inventory-select font-sub-heading' style={{width:'auto',opacity:'0'}} onChange={Sort}  >
<option value="" className='form-select-option'>Sort</option>
<option value="reset" className='form-select-option'>Reset</option>
<option value="hq" className='form-select-option'>High Quantity</option>
<option value="lq" className='form-select-option'>Low Quantity</option>

<option value="id" className='form-select-option'>Product ID</option>
</select>




<select name="" id="" className='form-select inventory-select font-sub-heading' style={{width:'auto',opacity:"0"}}>

<option value="" className='form-select-option'>Export</option>
<option value="reset" className='form-select-option'>CSV</option>
<option value="hq" className='form-select-option'>XLS 
</option>
<option value="">XLSX </option>
</select>


</article>




{
inventoryData.length!==0 && (
<>
<div className='inventory-table-container'>

<div className='invetory-table-header'>ID</div>
<div className='invetory-table-header'>Name</div>
<div className='invetory-table-header'>Quantity</div>

<div className='invetory-table-header'>Actions</div>




</div> 



</>
)
}





<div className='inventory-main-container' style={{backgroundColor:"white"}}>


{
inventoryData.map((item,index)=>{
const {_id,name,sku,inventoryData,upb}=item

const number=index+1;
const remainder=number%2


const allQuantity=inventoryData.map((item)=>{
return item.quantity
}).reduce((acc,cv)=>{
return acc + cv
},0)


let minimumExpirydate= inventoryData.length !== 0 ? inventoryData[0].expiryDate : 'N/A';

if(inventoryData.length!==0){

const allExpiryDate= inventoryData.map((item)=>item.expiryDate)




for (let i=0;i<allExpiryDate.length;i++){
const element=allExpiryDate[i]

const parsedElement=Date.parse(element)

const parsedMinimumExpiryDate=Date.parse(minimumExpirydate)





if (parsedElement<parsedMinimumExpiryDate){

 

 minimumExpirydate =element



}
}




}

const bulk= parseFloat( allQuantity/item.upb)


const piecesRemainder=(parseFloat(allQuantity)- parseInt(bulk)*item.upb)










return(

  <>









  
  <div  className='inventory-container'      style={{cursor:'',backgroundColor:remainder!==0?'white':'hsl(0,0%,95%)',}} data-id={_id}  key={index}>

<div className='inventory-properties'     onClick={(navigateToProductInfo)}  data-id={_id}  style={{cursor:'pointer'}}>{sku}</div>
<div  className='inventory-properties' data-id={_id}     style={{cursor:'pointer'}} onClick={(navigateToProductInfo)} >{name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()}</div>

<div className='inventory-properties' data-id={_id}  style={{cursor:'pointer'}} onClick={(navigateToProductInfo)}  >
{piecesRemainder === 0 ? `${parseInt(parseFloat(allQuantity / upb).toFixed(1))} 📦` : (piecesRemainder !== 0 ? `${parseInt(allQuantity / item.upb).toFixed(1)} 📦 ${piecesRemainder.toFixed(1)} 🔵` : "OOS")}
</div>



<div  className='inventory-properties' data-id={_id}> 

<div className='action-container'>
<div className='action-btn-indicator-container'>

{

(indicator==='inv' && focusId===_id) && (
  <>
  <div className='inventory-indicator'>
<div className='inventory-indicator-chat'>
Stock up
</div>

<div className='inventory-indicator-pointer'>

</div>

</div>
  
  </>
)

}





<button  className='btn btn-primary inventory-add-btn' 
data-id={_id}  
data-name={name}
onClick={(e)=>{handleAction(e,'stock');setUpb(item.upb)}}
onMouseEnter={()=>{setIndicator('inv');setFocusId(_id)}}  onMouseLeave={()=>{setIndicator('');setFocusId('')}} 


>
<FontAwesomeIcon  icon={faPlus} className='action'  onMouseEnter={()=>{setIndicator('inv');setFocusId(_id)}} />
</button>


</div>







<div className='action-btn-indicator-container'>

{

(indicator==='delete' && focusId===_id) && (
<>
<div className='inventory-indicator'>
<div className='inventory-indicator-chat'>
Delete
</div>

<div className='inventory-indicator-pointer'>

</div>

</div>

</>
)

}

<button  className='btn btn-danger inventory-delete-btn'  onMouseEnter={()=>{setIndicator('delete');setFocusId(_id)}}  onMouseLeave={()=>{setIndicator('');setFocusId('')}}  data-id={_id} data-name={name} onClick={(e)=>{handleAction(e,'delete');setProductToBeDeletedID(item._id)}}>
<FontAwesomeIcon  icon={faTrash}  onMouseEnter={()=>{setIndicator('delete');setFocusId(_id)}}   className='action-delete-btn action' style={{cursor:"pointer"}}></FontAwesomeIcon>

</button>

</div>





</div>

</div>




</div>
  
  </>
)
})
}










</div>




</section>








</section>





</main>

    
    </>
  )
}












 









</LayOut>





</>




 )
}


export default Invetory
