import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const ErrorPage=()=>{
  const navigate=useNavigate()
 return(
  <>
  <main  className="error-page-overlay">


  <div className="error-page-container">
<h1 className="error-header">404</h1>
<h3>OOPS! PAGE NOT FOUND </h3> 


<strong className="error-description">sorry the page you are looking for doesn't exist</strong>

<button  className="btn btn-secondary" onClick={()=>navigate('/')}>Back to Homepage</button>
</div>


   
  </main>


  
  </>
 )
}