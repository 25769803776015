         
     
import React, { useState, useEffect } from 'react'
import {useQuery,} from 'react-query'
import { Helmet } from "react-helmet";
import { useParams,useNavigate } from 'react-router-dom'

import { useGlobal, input } from './context'
import {  FaEye,FaEyeSlash } from 'react-icons/fa'; 
import axios from 'axios'
import { Response } from './lay-out';
import { Vortex } from 'react-loader-spinner';


























function ResetPassWord() {

const {prefix,loadingMessage,setLoadingMessage,setApiError,setUpdate}= useGlobal()

 

 




  const [showPassWord,setShowPassWord]=useState(false)

 const [newPassWord,setNewPassWord]=useState('')

 const [isSpinning,setIsSpinning] =useState(false)
 

const navigate= useNavigate()

const {token}= useParams()












  















  const handleSignInPassWordChange=(e)=>{
    setNewPassWord(e.target.value)
  
  }






const data = {
 
  newPassWord,
}
















  const handleShowPassWord=()=>{

     if(showPassWord){
       setShowPassWord(false)
       return
     }
    setShowPassWord(true)
   }





   

  

 



const resetPassWord=async()=>{


if (newPassWord===''){
  setApiError(true)
  setLoadingMessage('password can not be empty')
  return
}


if(!token){
 setApiError(true)
 setLoadingMessage('token doesnt exist')
 return
}


if(newPassWord.length<8){
  setApiError(true)
  setLoadingMessage('password must be atleast 8 characters')
  return
 }

setIsSpinning(true)
  

  const url=`${prefix}/users/reset-password/${token}`

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },

  };


  try{
   
    const response= await axios.patch(url,data,config)

    setApiError(false)
    setLoadingMessage('password reset successfully')
      
    
   

  }

  catch(error){
    setApiError(true)
    if(error.response){
     setLoadingMessage(error.response.data)
     return
    }

else if(error.request){

 setLoadingMessage('error processing request, try again')
 return

}


else{
 setLoadingMessage(error.message)
 
}


setLoadingMessage('error processing request, try again')

    
  }


  finally{
    setIsSpinning(false)
  }



  

}




























































return (
<>

<Helmet>
        
        <meta name="description" content="Software designed for tracking and managing inventories efficiently" />
        
    

       
      

        {/* Additional SEO Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="format-detection" content="telephone=no" />

        <title> - Software design to track and manage inventory efficiently</title>

      </Helmet>

      
      <main className='sign-up-main' style={{backgroundColor:'white',minHeight:'100vh'}}>

<section  className='sign-up-img'>
<p className='inventory-confirm'   style={{fontStyle:'italic',color:"green"}}>iVy</p>

<div className='sign-up-pitch font-text'>Boost your SME's efficiency with our smart inventory management.</div>

</section>




    
    <section  className='sign-up-content '>
  

  <h1 className='ivy-header' >ivy</h1>

 <h1 className='font-sub-heading'>Reset Password</h1>


   <section className='whole-sign-up-container'>
   








<div className='input-holder' >

<label className='legend form-label'   htmlFor="">input your new password</label>
  
  <div  className='signup-eye-password-container '>
  <input  type={showPassWord?'text':'password'}  name='password' value={newPassWord} onChange={handleSignInPassWordChange} className='signup-password form-control ' />
  <div className='eye'>



  {
     !showPassWord ? (
       <>
      <FaEye  onClick={handleShowPassWord}></FaEye>  
       </>
     ):(
       <>
       <FaEyeSlash onClick={handleShowPassWord}></FaEyeSlash>
       </>
     )
   }





  </div>
  
  </div>

  <br />

</div>



<div className='input-holder mobile-holder' >


<div   className='btn-container'   style={{display:'flex',justifyContent:'center', width:""}}>
    <button className='btn btn-primary submit-btn'  type='submit' onClick={resetPassWord}>
    {
    isSpinning ? (
      <>
      
    <Vortex
  visible={true}
  height="40"
  width="40"
  ariaLabel="vortex-loading"
  wrapperStyle={{}}
  wrapperClass="vortex-wrapper"
  colors={['white', 'white', 'white', 'white', 'white', 'white']}
  /> 
      
      </>
    ):(
      <>
    Submit
      </>
    )
   }
    
    
    
    
    </button>
    </div>
   {
    loadingMessage!=='' && (
      <>

      <Response></Response>
      
      </>
    )
   }
 
</div>





<div className='input-holder mobile-holder' >

<p style={{color:'black',fontSize:'0.9em',fontWeight:'bolder',cursor:'pointer'}}  onClick={()=>{

   navigate('/log-in')
    }}> back to log in </p>


    

<br />
 
</div>















   </section>
  
   
   
  










</section>
   
  






















</main>



    







 



















      
  </>
)




  
  


}






export default ResetPassWord
// 