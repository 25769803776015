import React, { useState, useEffect } from 'react'
import {useQuery,} from 'react-query'
import { Helmet } from "react-helmet";
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
//import {shoes, } from './context'
import { useGlobal, input } from './context'
import { FaSearch, FaPhone, FaTimes, FaEnvelope, FaFacebook, FaTwitter, FaInstagram,  FaEye,FaUser , FaCloud, FaPlug,FaEyeSlash } from 'react-icons/fa'; 
import axios from 'axios'

import { Response } from './lay-out';
import { Vortex,RotatingLines } from 'react-loader-spinner';

//import {cloth} from './filter'
//import { cl } from './context'
import {LoadingSpinner} from './spinner'




export const RefferallRegister=()=> {
 const {prefix,loadingMessage,setLoadingMessage,setApiError,}= useGlobal()


 
 
 const [hasSubmitButtonClicked,setHasSubmitButtonClicked]= useState(false)
 const [congrats,setCongrats]=useState('')
 


 const [tagId,setTagId] =useState('')
 const [isAgreed,setIsAgreed] =useState(false)
 const [isSpinning,setIsSpinning] =useState(false)




 const [data,setData]=useState({})

const   navigate =useNavigate()



































const register=async()=>{








setHasSubmitButtonClicked(true)






if(!data.firstName || !data.lastName || !data.email || !data.telephone){
  setApiError(true)
  setLoadingMessage("please fill all required details")
  
  return
}


 try{

   const config = {
     headers: {
       'Content-Type': 'application/json',
     },
   };


   setIsSpinning(true)

   const url = `${prefix}/management/referral-sign-up`;
     const response = await axios.post(url, data,config);
     if (response.status === 200) {
    
       setApiError(false)
 
    
     
    setCongrats('coupon')
    setTagId(response.data)
   
         
         // Successful response status is 200
       } 


 }

 catch(error){

   setApiError(true)
    if(error.response){


 




     setLoadingMessage(error.response.data)
    }

else if(error.request){

 setLoadingMessage('error processing request, try again')

}


else{
 setLoadingMessage(error.message)
}



 }


 finally{
   setHasSubmitButtonClicked(false)
   setIsSpinning(false)
   
 }

}




















if(!isAgreed){
 return(
  <>
  <section className='outright-policy-overlay'>
  <div className='outright-policy-container'>

   <article className='outright-policy-content'>
   <h4 style={{textAlign:'center'}}>Referral Policy - Terms and Conditions</h4>

   <ol>
    <li className='referral-policy-header'><strong>Eligibility:</strong>
      <ul>
        <li>The referral program is open to everyone, irrespective of whether the referrer has an account with our inventory management software or not.</li>
      </ul>
    </li>

    <li><strong>Referral Bonus:</strong>
      <ul>
        <li>Users will receive a referral bonus of 20,000 Naira for each new subscriber they refer to our outright subscription.</li>
        <li>The referral bonus will only be paid out if the referred person completes the payment for the outright subscription.</li>
        <li>There is no limit to the number of referrals a user can make, but each referral must result in a paid subscription to qualify for the bonus.</li>
      </ul>
    </li>

    <li><strong>Referral Process:</strong>
      <ul>
        <li>To ensure proper credit for referrals, the referred person must use the unique referral code or link provided by the referring user.</li>
        <li>Referral bonuses will be paid out within 48 hours of the referred person's subscription payment confirmation.</li>
        <li>If the referred person does not use the unique referral code or link, the referring user may not be eligible for the referral bonus.</li>
      </ul>
    </li>

    <li><strong>Payment Terms:</strong>
      <ul>
        <li>Referral bonuses will be paid to the referring user via the bank account whose name matches the name provided during registration.</li>
        <li>The referring user is responsible for any taxes or fees associated with receiving the referral bonus.</li>
      </ul>
    </li>

    <li><strong>Notification:</strong>
      <ul>
        <li>Referring users will be notified via email when a referred person has paid for the subscription.</li>
        <li>The referral bonus will be processed and paid out within 48 hours after the referred person's payment confirmation.</li>
      </ul>
    </li>

    <li><strong>Fraud Prevention:</strong>
      <ul>
        <li>We reserve the right to disqualify any user from the referral program if fraudulent activity is suspected.</li>
        <li>Users found referring themselves to exploit the referral bonus will have their accounts deactivated, and any bonuses they have accrued will not be given to them.</li>

        <li>Users must comply with all terms and conditions of the referral program to be eligible for the referral bonus.</li>
      </ul>
    </li>

    <li><strong>Program Modifications:</strong>
      <ul>
        <li>We reserve the right to modify or terminate the referral program at any time, with or without prior notice.</li>
        <li>Any changes to the referral program will be effective immediately upon posting on our website.</li>
      </ul>
    </li>

    <li><strong>Legal Compliance:</strong>
      <ul>
        <li>By participating in the referral program, users agree to comply with all applicable laws and regulations.</li>
        <li>We reserve the right to suspend or terminate any user's participation in the referral program for any violation of these terms or applicable laws.</li>
      </ul>
    </li>

    <li><strong>Contact Information:</strong>
      <ul>
      <li>If you wish to exercise any of these rights, please contact our customer support team at <a href="mailto:support@ivify.com.ng">support@ivify.com.ng</a>.</li>
      </ul>
    </li>
  </ol>

   </article>



  <div className='outright-btn-section'>
<button className='btn cancel-btn' onClick={()=>navigate('/referral')}>cancel</button>
<button className='btn btn-primary ' onClick={()=>setIsAgreed(true)}>Accept</button>

</div>


</div>
  

 
  </section>

  
  </>
 )
}

















return(
 <>

<Helmet>
        
        <meta name="description" content="Software designed for tracking and managing inventories efficiently" />
        
    

       
      

        {/* Additional SEO Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="format-detection" content="telephone=no" />

        <title> - Software design to track and manage inventory efficiently</title>

      </Helmet>


      {congrats==='coupon' && (


<>
<div className='congrat-overlay'>
<div className='congrat-modal' style={{flexDirection:'column',display:'flex',justifyContent:'center',alignItems:"center",padding:'8px'}}>
<div style={{width:'100%'}}>
<FaTimes  onClick={()=>{
 setCongrats('')

  setTagId('')
 
  
}}     style={{float:'right',cursor:'pointer'}} size={32}></FaTimes>
</div>

<p style={{textAlign:'center',fontWeight:'500',width:'90%'}}>Congrats, you have successfully register for our referral program, your coupon code is
<br /> 

<strong style={{color:'green', fontSize:'2.5rem'}}>{tagId}</strong>
<br />
Kindly copy this coupon, as it will be used to track your referral activity. You can give it to the person you refer to claim referral bonus.




</p>

</div>

</div>



</>
)

}






<main className='sign-up-main' style={{backgroundColor:''}}>

 <section  className='referral-sign-up-img'>
 <p className='inventory-confirm'   style={{fontStyle:'italic',color:"green"}}>iVy</p>

 <div className='sign-up-pitch font-text' style={{color:'black',fontWeight:'700',}}>Generate referall Coupon and start earning now.</div>

 </section>


 <section  className='sign-up-content '>
 <h1 className='ivy-header' >ivy</h1>
 <h1 className='font-sub-heading' > Generate Referral Coupon</h1>
<p style={{textAlign:"center"}}>fill up below details and generate referral coupon code and start earning</p>
 

  <section className='whole-sign-up-container'>
   



  <div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">First Name *</label>
    <input type="text" name='user-id'

     
      value={data.name} onChange={(e)=>setData((prev)=>({...prev,firstName:e.target.value}))}
      className={hasSubmitButtonClicked && !data.firstName  ? 'unfilled signup-form  form-control' : 'signup-form  form-control'}
    placeholder={hasSubmitButtonClicked && !data.firstName ? 'Please enter your first name' : ''}
    
    
    />
   <br />
</div>


<div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Last Name *</label>
    <input type="text" name='user-id'

     
      value={data.name} onChange={(e)=>setData((prev)=>({...prev,lastName:e.target.value}))}
      className={hasSubmitButtonClicked && !data.lastName  ? 'unfilled signup-form  form-control' : 'signup-form  form-control'}
    placeholder={hasSubmitButtonClicked && !data.lastName ? 'Please enter your last name' : ''}
    
    
    />
   <br />
</div>


   
<div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Email *</label>
    <input type="text" name='user-id'  value={data.email} onChange={(e)=>setData((prev)=>({...prev,email:e.target.value}))}
    
    className={hasSubmitButtonClicked && !data.email  ? 'unfilled signup-form  form-control' : 'signup-form  form-control'}
    placeholder={hasSubmitButtonClicked && !data.email ? 'Please enter your email' : ''}
    
    />
   <br />
</div>
   
   
   
<div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Telephone *</label>
    <input type="text" name='user-id'  value={data.telephone} onChange={(e)=>setData((prev)=>({...prev,telephone:e.target.value}))}
    className={hasSubmitButtonClicked && !data.telephone  ? 'unfilled signup-form  form-control' : 'signup-form  form-control'}
    placeholder={hasSubmitButtonClicked && !data.telephone ? 'Please enter your telephone number' : ''}
    
    />
   <br />
</div>

   
   
   
 
   

   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
 
   
   <div className='input-holder mobile-holder' >
   
   
   <p style={{color:'black',fontSize:'0.9em',fontWeight:'bolder',textAlign:'center',width:'70%'}} >By clicking the “ Generate Coupon” button, you agree to Ivy's <span style={{color:'green',cursor:'pointer',}}onClick={()=>setIsAgreed(false)}> terms and policy.</span> </p>
   
   
        
   
   
     
   </div>
   
   
   
   
   <div className='input-holder mobile-holder' >
   
   
   <div   className='btn-container'   style={{display:'flex',justifyContent:'center', width:""}}>
        <button className='btn btn-primary submit-btn'  type='submit' onClick={register}>
        {
       isSpinning ? (
         <>
         
       <Vortex
     visible={true}
     height="30"
     width="30"
     ariaLabel="vortex-loading"
     wrapperStyle={{}}
     wrapperClass="vortex-wrapper"
     colors={['white', 'white', 'white', 'white', 'white', 'white']}
     /> 
         
         </>
       ):(
         <>
       Generate Coupon
         </>
       )
     }  
        
        
        
        </button>
        </div>
       {
         loadingMessage!==''&&(
           <>
           <Response></Response>
           
           </>
         )
       }
     
   </div>
   
   

   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
       </section>















 </section>









</main>











 







 
 </>
)

}



