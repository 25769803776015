import React, { useState, useEffect } from 'react'
import {useQuery,} from 'react-query'
import { Helmet } from "react-helmet";
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
//import {shoes, } from './context'
import { useGlobal, input } from './context'
import { FaSearch, FaPhone, FaTimes, FaEnvelope, FaFacebook, FaTwitter, FaInstagram,  FaEye,FaUser , FaCloud, FaPlug,FaEyeSlash } from 'react-icons/fa'; 
import axios from 'axios'

import { Response } from './lay-out';
import { Vortex,RotatingLines } from 'react-loader-spinner';

//import {cloth} from './filter'
//import { cl } from './context'
import {LoadingSpinner} from './spinner'




export const Terms=()=> {
 const {prefix,loadingMessage,setLoadingMessage,setApiError,}= useGlobal()


 





const   navigate =useNavigate()






















































 return(
  <>
<section className='outright-policy-overlay'>
  <div className='outright-policy-container'>

    <article className='outright-policy-content'>
      <h4 style={{textAlign:'center'}}>Ivy - Terms and Conditions</h4>
      
      <ol className='outright-policy-list'>
        <li className='outright-policy-header'><strong>Subscription Duration:</strong>
          <ul>
            <li>The outright subscription provides lifetime access to our inventory management software without the need for recurring payments.</li>
            <li>Once subscribed, you will have unlimited access to all features and updates for the lifetime of the product.</li>
          </ul>
        </li>

        <li className='outright-policy-header'><strong>Payment Terms:</strong>
          <ul>
            <li>Payment for the outright subscription is a one-time fee, payable at the time of subscription.</li>
            <li>We accept various payment methods, including credit/debit cards, bank transfers, and online payment platforms.</li>
          </ul>
        </li>

        <li className='outright-policy-header'><strong>Refund Policy:</strong>
          <ul>
            <li>Due to the nature of the outright subscription model, refunds are not available once the subscription fee has been paid.</li>
            <li>We encourage users to thoroughly evaluate our software through our free trial before committing to the outright subscription.</li>
          </ul>
        </li>

        <li className='outright-policy-header'><strong>Usage and Access:</strong>
          <ul>
            <li>The outright subscription grants the subscriber non-exclusive, non-transferable access to our inventory management software.</li>
            <li>Subscribers are responsible for maintaining the confidentiality of their account credentials and must not share them with unauthorized parties.</li>
            <li>No part of the software's source code will be provided to the subscriber under any circumstances. We will not be giving out or sharing code with users.</li>
          </ul>
        </li>

        <li className='outright-policy-header'><strong>Hosting and Infrastructure Costs:</strong>
          <ul>
            <li>Subscribers to the outright subscription are responsible for the costs associated with hosting the application, backend infrastructure, and database.</li>
            <li>This includes but is not limited to expenses related to server hosting, cloud services, domain registration, and any additional infrastructure required to operate the software.</li>
          </ul>
        </li>

        <li className='outright-policy-header'><strong>Bug Fixes:</strong>
          <ul>
            <li>The Company will provide bug fixes and updates for issues directly related to the Version of the Software.</li>
            <li>Bug fixes will address critical issues, security vulnerabilities, or functionality impairments that impact the proper operation of the Version.</li>
            <li>Bug fixes will be provided at the discretion of the Company and may not include feature enhancements or changes to the Version's functionality.</li>
          </ul>
        </li>

        <li className='outright-policy-header'><strong>Customization Options:</strong>
          <ul>
            <li>Users have the option to request customization of the Software to meet their specific needs or preferences.</li>
            <li>Customization requests may include modifications to existing features, addition of new features, or changes to the user interface.</li>
            <li>Customization requests will be evaluated on a case-by-case basis, and charges may apply depending on the complexity of the requested changes.</li>
          </ul>
        </li>

        <li className='outright-policy-header'><strong>Charges for Customization:</strong>
          <ul>
            <li>Charges for customization services will be determined based on factors such as the scope of work, complexity of the requested changes, and estimated time and resources required for implementation.</li>
            <li>Users will be provided with a cost estimate for customization services prior to commencement of work, and charges will be invoiced accordingly.</li>
          </ul>
        </li>
        <li className='outright-policy-header'><strong>Handling Requests for Business Data:</strong>
      <ul>
        <li>Upon request, subscribers may seek access to their business data, including sales records, inventory levels, and other relevant information stored within our software.</li>
        <li>We will verify the identity of the user making the request to ensure that only authorized individuals have access to sensitive business information.</li>
        <li>Disclosure of business data will be carried out in compliance with applicable laws, contractual obligations, and user consent.</li>
        <li>We will provide secure access to the requested business data, using encrypted channels and implementing access controls to prevent unauthorized access.</li>
        <li>Requests for business data will be documented, including details of how the request was handled and any actions taken in response.</li>
        <li>We will offer support and guidance to users throughout the process of accessing their business data, addressing any questions or concerns they may have.</li>
      </ul>
    </li>

        <li className='outright-policy-header'><strong>Termination and Cancellation:</strong>
          <ul>
            <li>The outright subscription does not have an expiration date and cannot be terminated or canceled by the subscriber.</li>
            <li>In the event of any breach of our terms of service or misuse of the software, we reserve the right to suspend or terminate access to the subscription without refund.</li>
          </ul>
        </li>

        <li className='outright-policy-header'><strong>Modification of Terms:</strong>
          <ul>
            <li>We reserve the right to modify these terms and policies at any time, with or without prior notice.</li>
            <li>Any changes to the terms will be effective immediately upon posting on our website.</li>
          </ul>
        </li>

        <li className='outright-policy-header'><strong>Legal Compliance:</strong>
          <ul>
            <li>By subscribing to the outright subscription, you agree to comply with all applicable laws and regulations regarding the use of our software.</li>
            <li>We reserve the right to suspend or terminate access to the subscription for any violation of these terms or applicable laws.</li>
          </ul>
        </li>

        <li className='outright-policy-header'><strong>Contact Information:</strong>
          <ul>
          <li>If you wish to exercise any of these rights, please contact our customer support team at <a href="mailto:support@ivify.com.ng">support@ivify.com.ng</a>.</li>
          </ul>
        </li>
      </ol>
    </article>

    <div className='outright-btn-section'>
      <button className='btn cancel-btn' onClick={()=>navigate('/')}>Cancel</button>
      <button className='btn btn-primary '  onClick={()=>navigate('/sign-up')}>Accept</button>
    </div>

  </div>
</section>



  
  </>
 )




















}



