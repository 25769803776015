import React, { useState,} from 'react'
import { useGlobal } from './context'
import axios from 'axios'
import { Link,  useNavigate,useLocation } from 'react-router-dom'
import { Preloader } from './preloader';
import { FaBoxes, FaFileInvoiceDollar, FaReceipt, FaDesktop, FaUserCheck, FaChartLine } from 'react-icons/fa';


import { Vortex } from 'react-loader-spinner';


import { Response } from './lay-out';

import { TopLink } from './subscription';
import { Pitch } from './App';





export const About=()=>{





const navigate= useNavigate()









 return(
  <>
 <TopLink></TopLink>
 

 <main  className='support-whole-container about-whole-container'  style={{background:'white'}}>


<section className='about-first-section'>

 <article className='about-first-section-modal-container'>

  <div className='about-first-section-modal-content'>
    <strong>ABOUT US</strong>
    <h1 className='about-pitch'>Ivy transforms businesses worldwide</h1>
  <p className='about-pitch-follower'>Ivy is designed to help you start, manage, and grow your business seamlessly</p>

<button className='btn about-pitch-btn' onClick={()=>navigate('/sign-up')}>Get Started</button>
  </div>

  <div className='about-second-section-modal-content'>
  
  <div className='about-second-section-modal-content-small'>
  
  

  </div>

  </div>



 </article>


 
</section>







<section className='about-mission-section'>

  <h2>Our Mission</h2>
  <p className='about-mission-pitch'>Our mission is to deliver innovative technology solutions that drive efficiency and profitability for our clients. Ivy is the embodiment of this mission, offering comprehensive features that streamline everyday business processes and provide valuable insights into your operations.</p>

</section>



<section className='about-second-section'>


<article className='about-second-section-left'>
   <h3 className='about-second-section-left-header'>Why Choose Ivy?</h3>
   <p className='about-second-section-left-pitch'>With Ivy, you gain a robust tool designed to enhance and streamline your business operations.</p>
 </article>



 <article className='about-second-section-right'>

  <div className='about-reason-pitch-container'>
   <div className='reason-container-logo'><FaBoxes></FaBoxes></div>
   <strong className='reason-pitch-header'>Efficient Inventory Tracking</strong>
   <p className='reason-pitch'>Keep precise track of your stock levels, manage orders, and reduce the risk of overstocking or stockouts.</p>

  </div>

  <div className='about-reason-pitch-container'>
   <div className='reason-container-logo'>  <FaFileInvoiceDollar /> </div>
   <strong className='reason-pitch-header'> Comprehensive Sales Recording</strong>
   <p className='reason-pitch'> Accurately record all sales transactions, ensuring you have a clear and detailed history of your business activities.</p>

  </div>


  <div className='about-reason-pitch-container'>
   <div className='reason-container-logo'> <FaDesktop /></div>
   <strong className='reason-pitch-header'>Remote Monitoring</strong>
   <p className='reason-pitch'>Monitor your business activities from anywhere, at any time. Whether you’re at the office, at home, or on the go, Ivy keeps you connected.</p>

  </div>

  <div className='about-reason-pitch-container'>
   <div className='reason-container-logo'> <FaChartLine /></div>
   <strong className='reason-pitch-header'>Scalability</strong>
   <p className='reason-pitch'>Ivy grows with your business, offering features and capabilities that cater to both small retailers and large wholesalers.</p>

  </div>
  

 </article>



</section>

<section className='about-last-section'>

<div className='about-last-section-column'>

 <div className='about-last-section-pitch'>
  <h3 className='ready-register-heading'>Are you ready to register now?</h3>
 </div>

 <div className='last-pitch-btn-container'>
  <button className='btn last-pitch-register-btn' onClick={()=>navigate('/sign-up')}>Register</button>
  <button className='btn last-pitch-contact-btn' onClick={()=>navigate('/support')}>Contact Us</button>


 </div>

</div>

</section>



<section style={{width:"100%",height:"auto"}}>
 <Pitch></Pitch>


</section>


  
 </main>


    
  
  
  </>
 )
}