import React, { useState, useEffect,useRef, } from 'react'
import { useGlobal } from './context'
import axios from 'axios'
import { Link,  useNavigate,useLocation } from 'react-router-dom'
import { Preloader } from './preloader';
import { FaSearch ,FaQuestionCircle, FaHeadset, FaCommentDots} from 'react-icons/fa';

import { Vortex } from 'react-loader-spinner';


import { Response } from './lay-out';

import { TopLink } from './subscription';
import { Pitch } from './App';





export const Support=()=>{





const navigate= useNavigate()









 return(
  <>
 <TopLink></TopLink>
 

 <main  className='support-whole-container'>

<section className='support-image-container'>

<h1  className='support-pitch-header'>How can we help you?</h1>


</section>

<section className='support-block-container'>

<div className='support-block' onClick={()=>navigate('/support/contact-us')}>
      <div><FaHeadset size={24} /></div>
      <h3>Contact</h3>
    
      <p>If you encounter any issues or need assistance, please don't hesitate to contact our support team. We're here to help you with any questions or concerns you might have, ensuring a smooth and satisfactory experience with our services.</p>

    </div>
    <div className='support-block' onClick={()=>navigate('/support/faq')}>
      <div><FaQuestionCircle size={24} /></div>
      <h3>FAQ</h3>
      <p>Explore our frequently asked questions to find detailed information and answers about our services, features, and policies. This section is designed to help you resolve common queries quickly and efficiently.</p>

    </div>
    <div className='support-block'  onClick={()=>navigate('/support/feedback')}>
      <div><FaCommentDots size={24} /></div>
      <h3>Give Feedback</h3>
      <p>Your feedback is invaluable to us. Share your thoughts and suggestions to help us enhance our services and provide you with an even better experience. We appreciate your time and input in helping us improve.</p>

    </div>

</section>



<section style={{width:"100%",height:"auto"}}>
 <Pitch></Pitch>


</section>


  
 </main>


    
  
  
  </>
 )
}