import React, { useState, useEffect,useRef, } from 'react'
import { useGlobal } from './context'
import axios from 'axios'
import { Link,  useNavigate,useLocation } from 'react-router-dom'
import { Preloader } from './preloader';
import { FaCheck,FaTwitter,FaFacebook,FaLinkedin ,FaArrowLeft } from 'react-icons/fa'; 
import { Vortex } from 'react-loader-spinner';


import { Response } from './lay-out';









export const Outright=()=>{
 const {prefix,error,setError,loadingMessage,setLoadingMessage,setApiError} = useGlobal()



const [hasSubmitButtonClicked,setHasSubmitButtonClicked]= useState(false)

const [isSpinning,setIsSpinning] =useState(false)
const [isAgreed,setIsAgreed] =useState(false)
const [data,setData]=useState({})



const navigate= useNavigate()

const navigateToSignUp=()=>{

  navigate('/sign-up')
}





const handleSubmit=async()=>{



  setHasSubmitButtonClicked(true)

 if (!data.name || !data.email || !data.businessName || !data.telephone){
  setApiError(true)
  setLoadingMessage('please  fill all required input')
  return
 }



    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  setIsSpinning(true)
  
  
  try{
  
    const url=`${prefix}/management/outright-order`
    const response = await axios.post(url,data,config); 
    if(response.status===200){
  
  
      setApiError(false)
      setLoadingMessage("Thank you for your order! We have successfully received your order and our team is currently processing it. We will get back to you shortly with further details on the next steps. For any immediate assistance, please contact our support team. We appreciate your business!")
     
      
    
    }
  
  
  }
  
  
  catch(error){
    setApiError(true)
    setLoadingMessage('error processing request, please  try again')
  }
  
  finally{
    setIsSpinning(false)
  }
  
    
   
  }







if(!isAgreed){
 return(
  <>
  <section className='outright-policy-overlay'>
  <div className='outright-policy-container'>

   <article className='outright-policy-content'>
   <h4 style={{textAlign:'center'}}>Outright Subscription ( Lifetime Access ) - Terms and Policies</h4>
   
<ol >
    <li  className='outright-policy-header'><strong>Subscription Duration:</strong>
      <ul>
        <li>The outright subscription provides lifetime access to our inventory management software without the need for recurring payments.</li>
        <li>Once subscribed, you will have unlimited access to all features and updates for the lifetime of the product.</li>
      </ul>
    </li>

    <li className='outright-policy-header'><strong>Payment Terms:</strong>
      <ul>
        <li>Payment for the outright subscription is a one-time fee, payable at the time of subscription.</li>
        <li>We accept various payment methods, including credit/debit cards, bank transfers, and online payment platforms.</li>
      </ul>
    </li>

    <li className='outright-policy-header'><strong>Refund Policy:</strong>
      <ul>
        <li>Due to the nature of the outright subscription model, refunds are not available once the subscription fee has been paid.</li>
        <li>We encourage users to thoroughly evaluate our software through our free trial before committing to the outright subscription.</li>
      </ul>
    </li>

    <li className='outright-policy-header'><strong>Usage and Access:</strong>
      <ul>
        <li>The outright subscription grants the subscriber non-exclusive, non-transferable access to our inventory management software.</li>
        <li>Subscribers are responsible for maintaining the confidentiality of their account credentials and must not share them with unauthorized parties.</li>
        <li>No part of the software's source code will be provided to the subscriber under any circumstances. We will not be giving out or sharing code with users.</li>
      </ul>
    </li>
    <li>
      <strong className='outright-policy-header'>Hosting and Infrastructure Costs:</strong>
      <ul>
        <li>Subscribers to the outright subscription are responsible for the costs associated with hosting the application, backend infrastructure, and database.</li>
        <li>This includes but is not limited to expenses related to server hosting, cloud services, domain registration, and any additional infrastructure required to operate the software.</li>
      </ul>
    </li>

    <li>
      <strong className='outright-policy-header'>Bug Fixes:</strong>
      <ul>
        <li>The Company will provide bug fixes and updates for issues directly related to the Version of the Software.</li>
        <li>Bug fixes will address critical issues, security vulnerabilities, or functionality impairments that impact the proper operation of the Version.</li>
        <li>Bug fixes will be provided at the discretion of the Company and may not include feature enhancements or changes to the Version's functionality.</li>
      </ul>
    </li>

    <li><strong className='outright-policy-header'>Customization Options:</strong>
      <ul>
        <li>Users have the option to request customization of the Software to meet their specific needs or preferences.</li>
        <li>Customization requests may include modifications to existing features, addition of new features, or changes to the user interface.</li>
        <li>Customization requests will be evaluated on a case-by-case basis, and charges may apply depending on the complexity of the requested changes.</li>
      </ul>
    </li>

    <li><strong className='outright-policy-header'>Charges for Customization:</strong>
      <ul>
        <li>Charges for customization services will be determined based on factors such as the scope of work, complexity of the requested changes, and estimated time and resources required for implementation.</li>
        <li>Users will be provided with a cost estimate for customization services prior to commencement of work, and charges will be invoiced accordingly.</li>
      </ul>
    </li>
  

    <li><strong className='outright-policy-header'>Termination and Cancellation:</strong>
      <ul>
        <li>The outright subscription does not have an expiration date and cannot be terminated or canceled by the subscriber.</li>
        <li>In the event of any breach of our terms of service or misuse of the software, we reserve the right to suspend or terminate access to the subscription without refund.</li>
      </ul>
    </li>

    <li><strong className='outright-policy-header'>Modification of Terms:</strong>
      <ul>
        <li>We reserve the right to modify these terms and policies at any time, with or without prior notice.</li>
        <li>Any changes to the terms will be effective immediately upon posting on our website.</li>
      </ul>
    </li>

    <li><strong className='outright-policy-header'>Legal Compliance:</strong>
      <ul>
        <li>By subscribing to the outright subscription, you agree to comply with all applicable laws and regulations regarding the use of our software.</li>
        <li>We reserve the right to suspend or terminate access to the subscription for any violation of these terms or applicable laws.</li>
      </ul>
    </li>

    <li><strong className='outright-policy-header'>Contact Information:</strong>
      <ul>
      <li>If you wish to exercise any of these rights, please contact our customer support team at <a href="mailto:support@ivify.com.ng">support@ivify.com.ng</a>.</li>
      </ul>
    </li>
  </ol>

   </article>



  <div className='outright-btn-section'>
<button className='btn cancel-btn' onClick={()=>navigate('/subscriptions')}>cancel</button>
<button className='btn btn-primary ' onClick={()=>setIsAgreed(true)}>Accept</button>

</div>


</div>
  

 
  </section>

  
  </>
 )
}









 return(
  <>







 
 

<main className='outright-whole-container'>




  
<section className='outright-top-first-section'>



<div className='top-logo-container'>iVy</div>
  <div className='second-top-first-section'>
  <Link className='home-link' to={'/'}>Home</Link>

<Link className='home-link' to={'/subscriptions'}>Pricing</Link>
<Link className='home-link unimportant-home-link'to={'/terms-and-policy'} >Terms & Conditions</Link>
<Link className='home-link unimportant-home-link' to={'/support'}>Support</Link>
<button className='top-btn' onClick={navigateToSignUp}>Sign up</button>

</div>
</section>
<br />
<section className='guide-container outright-guide'>

  <FaArrowLeft style={{cursor:"pointer"}} onClick={()=>navigate('/subscriptions')}/> <strong>back</strong>

</section>

<section className='outright-second-section'>

  <div className='outright-first-part'>
    <div className='outright-first-part-header'>
<h5>Outright Subscription</h5>

<strong>&#x20A6; 150000 </strong>
    </div>


    <h3 className='outright-first-part-content'>Features</h3>

<article className='outright-props-overlay'>
<div className='outright-props-container'>
      <div  className='outright-props-title'>Users</div>
      <div  className='outright-props-value'>Unlimited</div>

    </div>

    <div className='outright-props-container'>
      <div  className='outright-props-title'>Sales Record</div>
      <div  className='outright-props-value'>Unlimited</div>

    </div>

    <div className='outright-props-container'>
      <div  className='outright-props-title'>No of Product</div>
      <div  className='outright-props-value'>Unlimited</div>

    </div>

    <div className='outright-props-container'>
      <div  className='outright-props-title'>Detail Financial Analysis</div>
      <div  className='outright-props-value'>Yes</div>

    </div>

    
    <div className='outright-props-container'>
      <div  className='outright-props-title'>Detail Product Analysis</div>
      <div  className='outright-props-value'>Yes</div>

    </div>

    <div className='outright-props-container'>
      <div  className='outright-props-title'>Sales Record Tracking and Management</div>
      <div  className='outright-props-value'>Yes</div>

    </div>

    <div className='outright-props-container'>
      <div  className='outright-props-title'>Inventory history Tracking </div>
      <div  className='outright-props-value'>Yes</div>

    </div>

    
    <div className='outright-props-container'>
      <div  className='outright-props-title'>Inventory level Tracking and Updates </div>
      <div  className='outright-props-value'>Yes</div>

    </div>

    <div className='outright-props-container'>
      <div  className='outright-props-title'>Receipt Generation</div>
      <div  className='outright-props-value'>Yes</div>

    </div>


</article>
    

    
    


    
    


    

   

  </div>
  <div className='outright-second-part'>
    
  <h3 className='outright-second-part-header' style={{textAlign:'center'}}>Order Information</h3> 
    <div className='outright-second-part-pitch'>
    <p>
    Due to the high value of this subscription, it is available by request only. Please fill out the form below to express your interest, and our team will contact you to discuss the details and next steps.
  </p>
    </div>

    <div className=''>



    <div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Full Name *</label>
    <input type="text" name='user-id'

     
      value={data.name} onChange={(e)=>setData((prev)=>({...prev,name:e.target.value}))}
      className={hasSubmitButtonClicked && !data.name  ? 'unfilled signup-form  form-control' : 'signup-form  form-control'}
    placeholder={hasSubmitButtonClicked && !data.name ? 'Please enter your full name' : ''}
    
    
    />
   <br />
</div>




    <div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Email *</label>
    <input type="text" name='user-id'  value={data.email} onChange={(e)=>setData((prev)=>({...prev,email:e.target.value}))}
    
    className={hasSubmitButtonClicked && !data.email  ? 'unfilled signup-form  form-control' : 'signup-form  form-control'}
    placeholder={hasSubmitButtonClicked && !data.email ? 'Please enter your email' : ''}
    
    />
   <br />
</div>
<div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Telephone *</label>
    <input type="text" name='user-id'  value={data.telephone} onChange={(e)=>setData((prev)=>({...prev,telephone:e.target.value}))}
    className={hasSubmitButtonClicked && !data.telephone  ? 'unfilled signup-form  form-control' : 'signup-form  form-control'}
    placeholder={hasSubmitButtonClicked && !data.telephone ? 'Please enter your telephone number' : ''}
    
    />
   <br />
</div>


<div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Business Name *</label>
    <input type="text" name='user-id' value={data.businessName} onChange={(e)=>setData((prev)=>({...prev, businessName:e.target.value}))}
    
    className={hasSubmitButtonClicked && !data.businessName  ? 'unfilled signup-form  form-control' : 'signup-form  form-control'}
    placeholder={hasSubmitButtonClicked && !data.businessName ? 'Please enter your telephone number' : ''}
    
    />
   <br />
</div>

<div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Referal Coupon </label>
    <input type="text" name='user-id' className="form-control  signup-form" value={data.coupon} onChange={(e)=>setData((prev)=>({...prev,
   coupon:e.target.value.toUpperCase()}))}/>
   <br />
</div>






<div className='input-holder' >
<br />
<label className='form-label legend'  htmlFor="">Reason for Request</label>
<textarea rows="4" cols="50" className="form-control  signup-form" type='text' style={{height:'150px'}}  ></textarea>
   <br />
</div>

<div className='input-holder mobile-holder' >


<div   className='btn-container'   style={{display:'flex',justifyContent:'center', width:""}}>
    <button className='btn btn-primary submit-btn'  type='submit' onClick={handleSubmit}>
    {
    isSpinning ? (
      <>
      
    <Vortex
  visible={true}
  height="40"
  width="40"
  ariaLabel="vortex-loading"
  wrapperStyle={{}}
  wrapperClass="vortex-wrapper"
  colors={['white', 'white', 'white', 'white', 'white', 'white']}
  /> 
      
      </>
    ):(
      <>
    Submit
      </>
    )
   }
    
    
    
    
    </button>
    </div>
   {
    loadingMessage!=='' && (
      <>

      <Response></Response>
      
      </>
    )
   }
 
</div>



    </div>

</div>



</section>

 



</main>


    
  
  
  </>
 )
}