import React, { useState, useEffect } from 'react'
import {useQuery,} from 'react-query'
import { Helmet } from "react-helmet";
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
//import {shoes, } from './context'
import { useGlobal, input } from './context'
import {FaSmile, FaFrown,FaTimes} from 'react-icons/fa'; 
import axios from 'axios'
import { Response } from './lay-out';
import { Vortex,RotatingLines } from 'react-loader-spinner';
import { Preloader } from './preloader';
import LayOut from './lay-out';







export const EmailReminder=()=>{
  const {mPrefix,setLoadingMessage,loadingMessage,setApiError,setShowReminder,userEmail,userName}=useGlobal()

const navigate=useNavigate()




  const [isSpinning,setIsSpinning]=useState(false)


 const sendMagiclink=async()=>{

  setIsSpinning(true)

  const data={
    email:userEmail,
    name:userName,
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };




  try{
    const url=`${mPrefix}/users/signup-link`
    const response= await axios.post(url,data,config)

    setApiError(false)
setLoadingMessage('A verification link has been sent to your email address, kindly check your email address to verify your email address, should in case you didnt receive the message please check your SPAM in your email, and remove it from spam')
  

  }

  catch(error){

  
    
    if (error.response) {
   
      setApiError(true)
setLoadingMessage('error processing request, please try again')
return
    }


    setLoadingMessage('error processing request, try again')
  }

  finally{
    setIsSpinning(false)
  }
}







 
 return(
  <>
<main className='verify-email-container'>


  <div className='verify-whole-content'>
  <h3>Verify your email address</h3>
  <div>To  start using this service,click the button below to verify your email address</div>
 
  

    <div className='retry-input-holder'>
    <button className='btn btn-primary submit-btn retry-btn verify-btn'  type='submit' onClick={sendMagiclink}>
    {
    isSpinning ? (
      <>
      
    <Vortex
  visible={true}
  height="40"
  width="40"
  ariaLabel="vortex-loading"
  wrapperStyle={{}}
  wrapperClass="vortex-wrapper"
  colors={['white', 'white', 'white', 'white', 'white', 'white']}
  /> 
      
      </>
    ):(
      <>
 Request For Verification 
      </>
    )
   }
    
    
    
    
    </button>


    {
    loadingMessage!=='' && (
      <>

      <Response></Response>
      
      </>
    )
   }
 

    
</div>
<button className='btn btn-secondary reminder-back-btn' onClick={()=>setShowReminder(false)}> Cancel </button>
<p>need help? <span style={{cursor:'pointer',color:'green'}}onClick={()=>navigate('/support/contact-us')}> contact support</span></p>


  </div>
  
</main>

 




  </>
 )
}















export const  VerifyEmail=()=>{
 
const {prefix,setLoadingMessage,setApiError,loadingMessage,mPrefix}=useGlobal()
 const [isVerified,setIsVerified]=useState(false)
const [isLoading,setIsLoading]=useState(true)
 const [isSpinning,setIsSpinning] =useState(false)
 const navigate=useNavigate()
 const {token}=useParams()

const para=useParams()
 const verifyEmailLink=async()=>{

  const config = {
   headers: {
     'Content-Type': 'application/json',
   },
 };



  try{
const url=`${prefix}/users/verify-email/${token}`

   const response= await axios.get(url,config,{  withCredentials: true,})

   if(response.status===200){
    //setIsLoading(false)
   setIsVerified(true)
 
   }
else{
 
 setIsVerified(false)
}
  

  }
  catch(err){


  }

  finally{
   setIsLoading(false)
  }
 }


 useEffect(()=>{


 
 verifyEmailLink()

 },[])






if (isLoading){
 return(
   <>
   

   <Preloader></Preloader>
   
   </>
 )
}










 return(
<>
<main className='verify-email-container'>

{
 isVerified ? (
  <>
   <p style={{fontSize:'1.2rem'}}> <FaSmile size={24} style={{color:"green"}}></FaSmile>  congrats your email has successfully been verified</p>
<button className='btn btn-secondary' onClick={()=>navigate('/log-in')}>Back To Log in</button>
 <div>

 </div>

  </>
 ):(
  <>
   <p style={{fontSize:'1.2rem'}}> <FaFrown size={24} style={{color:"red"}}></FaFrown> oops, an error occured verifying your email, retry</p>

   <button className='btn btn-secondary' onClick={()=>navigate('/log-in')}>Back To Log in</button>
 

  
  </>
 )
}












</main>

</>
 )

}