import React, { useState, useEffect,useRef, } from 'react'
import { useGlobal } from './context'
import axios from 'axios'
import { Link,  useNavigate,useLocation } from 'react-router-dom'
import { Preloader } from './preloader';
import { FaSearch ,FaQuestionCircle, FaHeadset, FaCommentDots} from 'react-icons/fa';

import { Vortex } from 'react-loader-spinner';


import { Response } from './lay-out';

import { TopLink } from './subscription';
import { Pitch } from './App';





export const Referral=()=>{
 const {setRef} = useGlobal()




const navigate= useNavigate()









 return(
  <>
 <TopLink></TopLink>
 

 <main  className='support-whole-container'>

<section className='referral-image-container'>
  <h1 className='support-pitch-header'>Refer and Win Today</h1>
  <p className='refferal-pitch'>Earn <span style={{fontWeight:'900',color:"yellow"}}>20,000 Naira </span> for every successful referral! Share your unique coupon code, and get paid when your referrals subscribe.</p>
  <p className='refferal-pitch'>Why wait any longer? Start referring now and watch your rewards grow!</p>

  <button className='btn pitch-referall-register-btn' onClick={()=>navigate('/referall/sign-up')}>REGISTER</button>
</section>




<section className='support-block-container'>

<div className='support-block' onClick={()=>navigate('/referall/sign-up')}>
      <div><FaHeadset size={24} /></div>
      <h3>Register</h3>
    
      <p>Unlock amazing rewards with our referral program! Earn 20,000 Naira for every new subscriber you refer to our inventory management software. There's no limit to the number of referrals you can make, so the more you refer, the more you earn! Plus, you don't need to have an account with us to participate. Simply share your unique coupon code, and once your referral subscribes, you'll receive your bonus within 48 hours. If you have any questions or need assistance, our support team is always here to help, ensuring you have a seamless experience with our referral program.</p>

    </div>

    <div className='support-block' onClick={()=>{setRef(true);navigate('/support/faq')}}>
      <div><FaQuestionCircle size={24} /></div>
      <h3>FAQ</h3>
      <p>Explore our frequently asked questions to find detailed information and answers about our referral programe, features, and policies. This section is designed to help you resolve common queries quickly and efficiently.</p>

    </div>



   
    <div className='support-block' onClick={()=>navigate('/support/contact-us')}>
      <div><FaHeadset size={24} /></div>
      <h3>Contact</h3>
    
      <p>If you encounter any issues or need assistance, please don't hesitate to contact our support team. We're here to help you with any questions or concerns you might have, ensuring a smooth and satisfactory experience with our services.</p>

    </div>

   

</section>



<section style={{width:"100%",height:"auto"}}>
 <Pitch></Pitch>


</section>


  
 </main>


    
  
  
  </>
 )
}