import React, { useState, useEffect } from 'react'
import {useQuery,} from 'react-query'
import { Helmet } from "react-helmet";
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
//import {shoes, } from './context'
import { useGlobal, input } from './context'
import { FaSearch, FaPhone, FaTimes, FaEnvelope, FaFacebook, FaTwitter, FaInstagram,  FaEye,FaUser , FaCloud, FaPlug,FaEyeSlash } from 'react-icons/fa'; 
import axios from 'axios'

import { Response } from './lay-out';
import { Vortex,RotatingLines } from 'react-loader-spinner';

//import {cloth} from './filter'
//import { cl } from './context'
import {LoadingSpinner} from './spinner'




export const PrivacyPolicy=()=> {
 const {prefix,loadingMessage,setLoadingMessage,setApiError,}= useGlobal()


 





const   navigate =useNavigate()






















































 return(
  <>
  <section className='outright-policy-overlay'>
        <div className='outright-policy-container'>

          <article className='outright-policy-content'>
            <h4 style={{ textAlign: 'center' }}>Privacy Policy</h4>

            <ol className='outright-policy-list'>
              <li className='outright-policy-header'><strong>Introduction:</strong>
                <ul>
                  <li >We are committed to protecting your privacy and ensuring the security of your personal information.</li>
                  <li >This Privacy Policy explains how we collect, use, and safeguard your information when you use our software and services.</li>
                </ul>
              </li>

              <li className='outright-policy-header'><strong>Information Collection:</strong>
                <ul>
                  <li>We collect personal information that you provide to us when you register, use our software, or contact us for support.</li>
                  <li>Information collected may include your name, email address, phone number, payment information, and any other details necessary to provide our services.</li>
                  <li>We may also collect non-personal information such as your IP address, browser type, and usage data through cookies and similar technologies.</li>
                </ul>
              </li>

              <li className='outright-policy-header'><strong>Use of Information:</strong>
                <ul>
                  <li>We use your personal information to provide, maintain, and improve our software and services.</li>
                  <li>Your information helps us to personalize your experience, process transactions, and provide customer support.</li>
                  <li>We may use your information to communicate with you about updates, promotions, and other relevant information related to our services.</li>
                </ul>
              </li>

              <li className='outright-policy-header'><strong>Information Sharing:</strong>
                <ul>
                  <li>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as described in this policy.</li>
                  <li>We may share your information with third-party service providers who assist us in operating our software, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.</li>
                  <li>We may also disclose your information when required by law, to enforce our policies, or to protect our or others' rights, property, or safety.</li>
                </ul>
              </li>

              <li className='outright-policy-header'><strong>Data Security:</strong>
                <ul>
                  <li>We implement industry-standard security measures to protect your data from unauthorized access, use, or disclosure.</li>
                  <li>While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</li>
                </ul>
              </li>

              <li className='outright-policy-header'><strong>Data Retention:</strong>
                <ul>
                  <li>We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, or as required by law.</li>
                  <li>When your information is no longer needed, we will securely delete or anonymize it.</li>
                </ul>
              </li>

              <li className='outright-policy-header'><strong>Your Rights:</strong>
                <ul>
                  <li>You have the right to access, correct, or delete your personal information that we hold.</li>
                  <li>You can manage your communication preferences and opt out of certain communications at any time.</li>
                  <li>If you wish to exercise any of these rights, please contact our customer support team at <a href="mailto:support@ivify.com.ng">support@ivify.com.ng</a>.</li>
                </ul>
              </li>

              <li className='outright-policy-header'><strong>Cookie Policy:</strong>
                <ul>
                  <li>We use cookies to enhance your experience on our platform, analyze usage, and provide personalized content.</li>
                  <li>By using our software, you consent to our use of cookies in accordance with our Cookie Policy.</li>
                  <li>You can manage your cookie preferences through your browser settings, but some features of our software may not function properly without cookies.</li>
                </ul>
              </li>

              <li className='outright-policy-header'><strong>Changes to Privacy Policy:</strong>
                <ul>
                  <li>We reserve the right to modify this Privacy Policy at any time, with or without prior notice.</li>
                  <li>Any changes to the policy will be effective immediately upon posting on our website.</li>
                </ul>
              </li>

              <li className='outright-policy-header'><strong>Contact Information:</strong>
                <ul>
                  <li>If you have any questions or concerns about this Privacy Policy, please contact our customer support team at <a href="mailto:privacy@email.com">support@ivify.com.ng</a>.</li>
                </ul>
              </li>
            </ol>
          </article>

          <div className='outright-btn-section'>
            <button className='btn cancel-btn' onClick={() => navigate('/')}>Cancel</button>
            <button className='btn btn-primary' onClick={() => navigate('/sign-up')}>Accept</button>
          </div>

        </div>
      </section>


  
  </>
 )




















}



