import React, { useState, useEffect,useRef, } from 'react';

import { FaPlus,FaMinus,FaCheck,FaTwitter,FaFacebook,FaLinkedin,FaArrowLeft,FaTimes,} from 'react-icons/fa';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { useGlobal } from './context'
import { useNavigate } from 'react-router-dom';
import { subscription } from './faq';
import { Pitch } from './App';
import { HiMenu } from 'react-icons/hi';





 















export    const TopLink=()=>{
  const [isAsideHidden,setIsAsideHidden]=useState(true)
  const navigate= useNavigate()

  const navigateToSignUp=()=>{

    navigate('/sign-up')
  }

  const toggle=()=>{
    if (isAsideHidden){
      setIsAsideHidden(false)
    }
  
    else{
      setIsAsideHidden(true)
    }
  
  }


  return<>

<section className='top-first-section'>
  <div className='top-logo-container'>ivy</div>
  <div className='second-top-first-section'>
  <Link className='home-link' to={'/'}>Home</Link>

<Link className='home-link' to={'/subscriptions'}>Pricing</Link>
<Link className='home-link unimportant-home-link'to={'/terms-and-policy'} >Terms & Conditions</Link>
<Link className='home-link unimportant-home-link' to={'/support'}>Support</Link>
<button className='top-btn' onClick={navigateToSignUp}>Sign up</button>





  </div>


</section>







<section  className={isAsideHidden?' hidden-top-nav':'mobile-top-nav'}>
<section className='mobile-home-first-section'>
  <div className='first-logo-container' onClick={()=>{
    navigate('/')
    if (!isAsideHidden){
      setIsAsideHidden(true)
    }
  }}>ivy</div>
  <div className='mobile-second-home-first-section'>



<div>
{
    isAsideHidden ? (
      <>
      <HiMenu fontSize={30} style={{marginLeft:'3vw',cursor:"pointer",color:'green',transition:'0.5s'}} onClick={toggle}></HiMenu>
      </>
    ):(
      <>
      <FaTimes  fontSize={30} style={{marginLeft:'3vw',color:'green',transition:'0.5s',cursor:'pointer'}} onClick={toggle} ></FaTimes>
      </>
    )
  }
</div>


  </div>


</section>

<section>
        <section className={`mobile-home-links ${!isAsideHidden ? 'show' : 'hide-mobile-home-links'}`}>
        
          <Link className='home-navigation-link' to={'/sign-up'}>Sign Up</Link>
          <Link className='home-navigation-link' to={'/log-in'}>Log In</Link>
          <Link className='home-navigation-link' to={'/subscriptions'}>Pricing</Link>
          <Link className='home-navigation-link' to={'support/faq'}>FAQs</Link>
          <Link className='home-navigation-link' to={'/about'}>About</Link>
        </section>
      </section>



</section>


  
  
  </>
}








const basic={
  name:"Basic",
  amount:'3225',
  users:'2',
  product:'500',
  financial:'Basic',
  prAnalysis:"No",
  inTracking:"No",
  

}

const standard={
  name:"Standard",
  amount:'5375',
  users:'3',
  product:'800',
  financial:'Basic',
  prAnalysis:"No",
  inTracking:"No",
 


}


const premium={
  name:"Premium",
  amount:'10750',
  users:'5',
  product:'1200',
  financial:'Detail',
  prAnalysis:"Yes",
  inTracking:"Yes",
 


}








 const Subscription=()=>{

  const {prefix} = useGlobal()
  const [isLoading,setIsLoading]=useState(false)
  const [isFetched,setIsFetched]=useState(false)
  const subAmount=sessionStorage.getItem('sub-amount')
  const subType=sessionStorage.getItem('sub-type')
  const navigate= useNavigate()
  const [indexArray,setIndexArray]=useState([])
  const [error,setError]=useState('')
  const [instance,setInstance]=useState({})








  const handlePayment = async(sentEmail,sentMetaData) => {


    const source = axios.CancelToken.source();
    const timeOutId = setTimeout(() => {
      
     source.cancel('Request timed out');
   }, 180000);
   
   
    const sourceData={
   
     email: sentEmail,
     amount:subAmount * 100,
     metadata:sentMetaData,
    
    }
    
   
   
    try{
   
   
   const url= `${prefix}/shops/initiate-squad`
   const response= await axios.post (url,sourceData,{withCredentials:true})
   setIsFetched(true)
   
 
   const location=response.data.data.data.checkout_url
   
   window.location.href = location;
   
   
   return
   
    
   
    }
   
   
    catch(error){
   
     
     if (axios.isCancel(error)) {
     
      setError('Request timed out. Please try again.');
      return;
   }
   
   if (error.message === 'Network Error') {
      setError('Oops, network error, check your internet.');
      return;
   }

   
   if (error.response) {
      if (error.response.data.message === 'unauthorized') {
          navigate('/log-in');

          return
        
      }} 
   
   
   
   
   
   setError('Oops, something went wrong, try again.');
   setIsLoading(false)
    }
   
   
   
     finally{
      clearTimeout(timeOutId);
     
     }
   
    
    
   };








   const fetchProfile = async () => {

setIsLoading(true)

    if (!subAmount || !subType){
     
     return
    }
  
  
  
  
  
    const source = axios.CancelToken.source();
  
    const timeOutId = setTimeout(() => {
  
     setError('Timed out')
     
        source.cancel('Request timed out');
    }, 180000);
  
    try {
        const url = `${prefix}/users/my-profile`;
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            cancelToken: source.token,
        };
  
        const response = await axios.get(url, config);
  
   
  
  
  
      
        const shopFound = response.data.data.shopFound;
        const profileData = response.data.data.data;
  
       
       
        
          
            const sentMetaData={
             subscriptionType:subType,
             shop:shopFound._id,
             tag:shopFound.tag
            }
  
          await handlePayment(profileData.email,sentMetaData)  
           
        
    } 
    
    
    catch (error) {

  
  
  
        
  
        if (axios.isCancel(error)) {
          
            setError('Request timed out. Please try again.');
            return;
        }
  
        if (error.message === 'Network Error') {
            setError('Oops, network error, check your internet.');
            return;
        }
  
        if (error.response) {
            if (error.response.data.message === 'unauthorized') {
                navigate('/log-in');
                setError(error.response.data.message);
            }} 
        
      
     
  
        setError('Oops, something went wrong, try again.');
        setIsLoading(false)
    } 
    
    
    finally {
  

        clearTimeout(timeOutId);
      
    }
  };
  



  


  const navigateToSignUp=()=>{

    navigate('/sign-up')
  }









const subscribe= async(type,amount,plan)=>{

  sessionStorage.setItem('sub-amount',amount)
  sessionStorage.setItem('sub-type',type)

  setInstance(plan)



 
}








const handleIndex=(parameter,instance)=>{
 
  
  if (instance==='add'){
   setIndexArray((prev)=>[...prev,parameter])
  }
  
  else{
   setIndexArray(indexArray.filter((item)=>item!==parameter))
  }
  
  
  }

 
 useState(()=>{

  setIsFetched(false)

 },[])





if(error  && !isFetched){
  return (
    <>
  
    <div style={{width:'100vw',height:"100vh",display:'flex',justifyContent:'center',alignItems:'center'}} className='alert'>
    <div style={{marginBottom:"1%"}}>{error}</div>
    <button  className='btn btn-primary retry-btn'  onClick={()=>{window.location.reload()}}>Retry</button>
    </div>
  
    </>
  )
}



const hasProperties= Object.keys(instance).length>0



 return (
  <>
   <TopLink></TopLink>



{
  isLoading && !isFetched  && (
    <>
 <div className='subscription-loader-container'>
    <div className="loading-spinner"  style={{backgroundColor:"transparent"}}>
       <div className="circle" style={{border:"4px solid black",borderTop:"4px solid transparent"}}></div>
      
                </div>

    </div>   
    
    </>
  )
}

{

hasProperties ?(
  <>
  <main  className='subscription-whole-container '>

  
<main className='outright-whole-container individual-whole-container' >
  

<section className='guide-container'>

  <FaArrowLeft style={{cursor:"pointer"}} onClick={()=>setInstance({})}/> <strong>back</strong>

</section>

<h1 style={{textAlign:'center'}}>SUBSCRIPTION DETAILS</h1>
  
<br />
<section className='outright-second-section subscription-second-section'>
  
  <div className='outright-first-part  reverse-first-part'>
    <div className='outright-first-part-header'>
<h5>Monthly Subscription</h5>

<strong style={{fontSize:"1.5rem"}}>{instance.name}  </strong>
    </div>


    <h3 className='outright-first-part-content'>Features</h3>

<article className='outright-props-overlay'>
<div className='outright-props-container'>
      <div  className='outright-props-title'>Users</div>
      <div  className='outright-props-value'>{instance.users} </div>

    </div>

    <div className='outright-props-container'>
      <div  className='outright-props-title'>Sales Record</div>
      <div  className='outright-props-value'>{instance.product} </div>

    </div>

   

    <div className='outright-props-container'>
      <div  className='outright-props-title'>Detail Financial Analysis</div>
      <div  className='outright-props-value'>{instance.financial} </div>

    </div>

    
    <div className='outright-props-container'>
      <div  className='outright-props-title'>Detail Product Analysis</div>
      <div  className='outright-props-value'>{instance.prAnalysis}</div>

    </div>

    <div className='outright-props-container'>
      <div  className='outright-props-title'>Sales Record Tracking and Management</div>
      <div  className='outright-props-value'>Yes</div>

    </div>

    <div className='outright-props-container'>
      <div  className='outright-props-title'>Inventory history Tracking </div>
      <div  className='outright-props-value'>{instance.inTracking}</div>

    </div>

    
    <div className='outright-props-container'>
      <div  className='outright-props-title'>Inventory level Tracking and Updates </div>
      <div  className='outright-props-value'>Yes</div>

    </div>

    <div className='outright-props-container'>
      <div  className='outright-props-title'>Receipt Generation</div>
      <div  className='outright-props-value'>Yes</div>

    </div>


</article>
    

    
    


    
    


    

   

  </div>
  <div className='outright-second-part  reverse-second-part '>
    <h4  style={{textAlign:'center'}}>SUMMARY</h4>
  
  <div className='outright-second-part-pitch '>
  <hr />
  <div  className='subscription-total-show'>
    <div>
    Subtotal  

    </div>
    
    <strong>   &#x20A6; {instance.amount}  </strong>
     </div>
     <hr />

     <div  className='subscription-total-show'>
    <div>
    Total Amount 

    </div>
    
    <strong>   &#x20A6; {instance.amount}   </strong>
     </div>
     <hr />
  </div>
    
  

<div className='sub-confirm-container'>

<button className='btn btn-primary' style={{width:'90%'}} onClick={fetchProfile}>PROCEED</button>
   
</div>
   

</div>



</section>

 



</main>

  </main>

  </>
):(
  <>
  <main  className='subscription-whole-container'>








<section className='subscription-first-line'>
 <h5  className='subscription-title'>Subscription</h5>

 <div className='subscription-indicator'>
  Monthly
 </div>

</section>

<div className='subcription-container-scrollable'>
<section className='subscription-block-container'>








<div  className='subscription-block'>
  <div className='block-first-line'>
  <div>BASIC</div>


  </div>
  <div className='block-second-line'>

    <div>&#x20A6;3,225</div>
  </div>
  <div className='block-third-line'>

  <div><FaCheck style={{color:'green'}}></FaCheck>  2 Users</div>
  <div><FaCheck style={{color:'green'}}></FaCheck>  500 Sales Record/Month</div>


  </div>

<div className='block-fourth-line'>
<button className='sub-btn'   onClick={()=>subscribe('basic',3225,basic)}>SUBSCRIBE</button>

</div>


<div className='block-fifth-line'>

<div  className='more-ft'>more features</div>

  <div><FaCheck style={{color:'green'}}></FaCheck> Basic Financial Analysis</div>
  <div><FaCheck style={{color:'green'}}></FaCheck>Receipt Generation</div>
 
 <div><FaCheck style={{color:'green'}}></FaCheck>Inventory level tracking</div>
  

</div>



 </div>



















 <div  className='subscription-block'>
  <div className='block-first-line'>
  <div>STANDARD</div>


  </div>
  <div className='block-second-line'>

    <div>&#x20A6;5,375</div>
  </div>
  <div className='block-third-line'>

  <div><FaCheck style={{color:'green'}}></FaCheck>  3 Users</div>
  <div><FaCheck style={{color:'green'}}></FaCheck>  800 Sales Record/Month</div>


  </div>

<div className='block-fourth-line'>
<button className='sub-btn' onClick={()=>subscribe('standard',5375,standard)}>SUBSCRIBE</button>

</div>

<div className='block-fifth-line'>

<div  className='more-ft'>more features</div>

  <div><FaCheck style={{color:'green'}}></FaCheck> Basic Financial Analysis</div>

  <div><FaCheck style={{color:'green'}}></FaCheck>Receipt Generation</div>
 
  <div><FaCheck style={{color:'green'}}></FaCheck>Inventory level tracking</div>

  

</div>


 </div>











 <div  className='subscription-block'>
  <div className='block-first-line'>
  <div>PREMIUM</div>


  </div>
  <div className='block-second-line'>

    <div>&#x20A6;10,750</div>
  </div>
  <div className='block-third-line'>

  <div><FaCheck style={{color:'green'}}></FaCheck>  5 Users</div>
  <div><FaCheck style={{color:'green'}}></FaCheck>  1200 Sales Record/Month</div>
  



  </div>

<div className='block-fourth-line'>
<button className='sub-btn'  onClick={()=>subscribe('premium',10750,premium)}>SUBSCRIBE</button>

</div>

<div className='block-fifth-line'>

<div  className='more-ft'>more features</div>
<div><FaCheck style={{color:'green'}}></FaCheck> All features in Standard</div>
<div><FaCheck style={{color:'green'}}></FaCheck> Batch History Tracking</div>
  <div><FaCheck style={{color:'green'}}></FaCheck> Detail Financial Analysis</div>

  <div><FaCheck style={{color:'green'}}></FaCheck> Detail Product Analysis</div>

</div>



 </div>





 <div  className='subscription-block'>
  <div className='block-first-line'>
  <div>OUTRIGHT</div>


  </div>
  <div className='block-second-line'>

    <div>&#x20A6;150,000</div>
  </div>
  <div className='block-third-line'>

  <div><FaCheck style={{color:'green'}}></FaCheck>  Unlimited Users</div>
  <div><FaCheck style={{color:'green'}}></FaCheck>  Unlimited Sales Record</div>
  



  </div>

<div className='block-fourth-line'>
<button className='sub-btn'   onClick={()=>{navigate('/subscriptions/outright')}}>SUBSCRIBE</button>

</div>

<div className='block-fifth-line'>

<div  className='more-ft'>more features</div>
<div><FaCheck style={{color:'green'}}></FaCheck> All features in Standard</div>
<div><FaCheck style={{color:'green'}}></FaCheck> Batch History Tracking</div>
  <div><FaCheck style={{color:'green'}}></FaCheck> Detail Financial Analysis</div>

  <div><FaCheck style={{color:'green'}}></FaCheck> Detail Product Analysis</div>

</div>



 </div>

























</section>

</div>


<section className='refer-modal'>
  <div className='refer-modal-first-part'>
    <div className='refer-modal-pitch'>Earn  <span style={{color:'green'}}>&#x20A6;20,000 </span> bonus and more on our Referral Programme. </div>
    <div className='refer-modal-guide-container'>
      <strong className='refer-guide-heading'>
        <div className='refer-step-number'>1</div>
        Create an Account
      </strong>
      <div className='refer-step'>
        Go to our referral page and register with your email, phone number, and name to generate a unique referral coupon code.
      </div>
    </div>

    <div className='refer-modal-guide-container'>
      <strong className='refer-guide-heading'>
        <div className='refer-step-number'>2</div>
        Refer Someone
      </strong>
      <div className='refer-step'>
        Give your coupon code to a new subscriber for them to use when they subscribe.
      </div>
    </div>

    <div className='refer-modal-guide-container'>
      <strong className='refer-guide-heading'>
        <div className='refer-step-number'>3</div>
        Get Paid
      </strong>
      <div className='refer-step'>
        Our team will reach out to you once the person you referred subscribes, and you will get paid within 48 hours.
      </div>
    </div>




<button className='btn refer-btn' onClick={()=>navigate('/referall/sign-up')}>REGISTER NOW</button>


  </div>



  <div className='refer-modal-second-part'>

  </div>
</section>


<section className='sub-faq-section'>


<article className='faq-content-holder sub-faq-container'>



<h3 style={{color:'green'}}>Frequently Asked Question</h3>


{
 subscription.map((content,index)=>{
  return(
   <>
   
   <div className='faq-question-symbol-container' key={index}>
   
<div className='symbol-container'>
{indexArray.includes(index) ?(
    <>
    <FaMinus className='faq-symbol sub-faq' onClick={()=>handleIndex(index,'remove')}></FaMinus>
    </>
   ):(
    <>
    <FaPlus className='faq-symbol sub-faq' onClick={()=>handleIndex(index,'add')}></FaPlus>
    </>
   )
   
  }


</div>

  


<div className='faq-question-answer-container' key={index}>
<div className='faq-question sub-faq'>
{content.question} </div>

{
indexArray.includes(index)    && (
<>
<div className='faq-answer sub-faq'>  {content.answer} </div> 
</>
)
}

</div>


 

   </div>
   
   </>
  )
 })
}



</article>
</section>







</main>
<section className='footer-modal'>

  <div style={{textAlign:'center',fontSize:'1rem',color:'white',fontWeight:'500'}}>Why wait any longer?</div>
  <div style={{textAlign:'center',fontSize:'2rem',color:'white',fontWeight:'500',width:''}} className='streamline'>Streamline your inventory management
and boost your business efficiency</div>
<button className='footer-register-btn' onClick={navigateToSignUp}>Register</button>



<section className='mobile-footer-link-container'>

  
<div className='mobile-link-content-container'>

<div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column',alignItems:"center",justifyContent:'center'}}>    
<div className='footer-logo' >ivy</div>
<div className='footer-statement' style={{textAlign:'center'}}>Ivy empowers small businesses and retailers to streamline their inventory processes, providing insights that enhance stock management.</div>
</div>
</div>



<div className='footer-link-container'>


<div className='mobile-link-content-container ' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Quick links</div>


<Link className='quick-links' to={'/about'}>About Us</Link>
<Link className='quick-links'  to={'/support'}>Support</Link>
<Link className='quick-links'  to={'/support/faq'}>FAQ</Link>
<Link className='quick-links'  to={'/referral'}>Refer & Win</Link>


  </div>


</div>
<div className='mobile-link-content-container quick-links-container' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Legal</div>

<Link className='quick-links' to={'/subscriptions'}>Pricing</Link>
<Link className='quick-links' to={'/terms-and-policy'}>Terms & Conditions</Link>
<Link className='quick-links' to={'/privacy-policy'}>Privacy Policy</Link>

  </div>


</div>





</div>

<section  style={{width:'100%',backgroundColor:'',display:'flex',justifyContent:"flex-start",alignItems:'center',height:'auto'}}>
<div className='last-link' style={{backgroundColor:''}}>

<div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

<div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'yellow',}} >Contact Us</div>
<div style={{marginTop:"0px",display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'white',fontSize:'1.0rem'}} > support@ivify.com.ng</div>




<section style={{width:'100%',}}>
<article className='footer-social-link-container'>
  <a href='https://mobile.x.com/ivy_encoded' className='social-link' target="_blank">
    <FaTwitter />
  </a>
 
  <a href='https://www.facebook.com/profile.php?id=61561915580549&name=xhp_nt__fb__action__open_user' className='social-link' target="_blank">
    <FaFacebook />
  </a>

  <a href='https://www.linkedin.com/company/ivifyng/' className='social-link' target="_blank">
    <FaLinkedin className='social-link' />
  </a>
</article>

</section>

</div>


</div>


</section>




















</section>








<section className='whole-footer-container'>

<article className='footer-link-container'>






<div className='link-content-container'>

<div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column',}}>    
<div className='footer-logo' >iVy</div>
<div className='footer-statement' >Ivy empowers small businesses and retailers to streamline their inventory processes, providing insights that enhance stock management</div>





</div>



</div>

<div className='link-content-container ' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Quick links</div>


<Link className='quick-links' to={'/about'}>About Us</Link>
<Link className='quick-links'  to={'/support'}>Support</Link>
<Link className='quick-links'  to={'/support/faq'}>FAQ</Link>
<Link className='quick-links' to={'/referral'}>Refer & Win</Link>


  </div>


</div>
<div className='link-content-container quick-links-container' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Legal</div>

<Link className='quick-links' to={'/subscriptions'}>Pricing</Link>
<Link className='quick-links' to={'/terms-and-policy'}>Terms & Conditions</Link>
<Link className='quick-links' to={'/privacy-policy'}>Privacy Policy</Link>

  </div>


</div>


<div className='link-content-container quick-links-container' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'yellow',}} >Contact Us</div>
<div style={{marginTop:"0px",display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'white',fontSize:'1.0rem'}} >Email: support@ivify.com.ng</div>




<section style={{width:'100%',}}>
<article className='footer-social-link-container'>




  <a href='https://mobile.x.com/ivy_encoded' className='social-link' target="_blank">
    <FaTwitter />
  </a>
 
  <a href='https://www.facebook.com/profile.php?id=61561915580549&name=xhp_nt__fb__action__open_user' className='social-link' target="_blank">
    <FaFacebook />
  </a>

  <a href='https://www.linkedin.com/company/ivifyng/' className='social-link' target="_blank">
    <FaLinkedin className='social-link' />
  </a>









</article>
</section>

  </div>


</div>


</article>
</section>



<div className='font-text copy-right-section'  > &copy; Copyright Encoded Technology  2024 . All Rights Reserved.</div>
</section>
 
  
  </>
)




}






  </>
 )
}


export default Subscription;