import {
 QueryClient,
 QueryClientProvider,

}from 'react-query'


const queryClient=new QueryClient()



export {queryClient,QueryClientProvider}

































