         
     
import React, { useState, useEffect } from 'react'
import {useQuery,} from 'react-query'
import { Helmet } from "react-helmet";
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
//import {shoes, } from './context'
import { useGlobal, input } from './context'
import { FaCheck,FaTwitter,FaFacebook,FaLinkedin,FaChevronRight,FaChevronLeft,FaTimes } from 'react-icons/fa'; 

import { HiMenu } from 'react-icons/hi';
import axios from 'axios'





import {LoadingSpinner} from './spinner'





export const Pitch=()=>{


  const navigate=useNavigate()


  const navigateToSignUp=()=>{

    navigate('/sign-up')
  }









  return(
    <>


<section className='footer-home-modal' >

<article className='brief-plan-info-container '>
  <div className='brief-partition text-partition'>
    <div><FaCheck  style={{color:'green'}} size={20}/> 2 Users</div>
    <div><FaCheck  style={{color:'green'}} size={20}/> 500 Sales Record/Month</div>
    
    <div><FaCheck  style={{color:'green'}} size={20}/> Receipt Generation</div>
    
  </div>
  <div className='brief-partition'>

    <strong className='brief-price-heading'>&#x20A6;3,225/m</strong>
    <div style={{textAlign:'',width:'100%',backgroundColor:''}}> Starting Price</div>
    <div style={{textAlign:'',width:'100%',backgroundColor:'',display:'',justifyContent:'',alignItems:''}}>

      <button className='check-plan-btn' onClick={()=>navigate('/subscriptions')}>Check all plans</button>  
    
    </div>
    
    </div>
    <div className='brief-partition text-partition'>
    <div><FaCheck  style={{color:'green'}} size={20}/> Basic Business Analysis</div>
    <div><FaCheck  style={{color:'green'}} size={20}/> Detail Product  Evaluation</div>
    <div><FaCheck  style={{color:'green'}} size={20}/> Basic Finacial Analysis</div>
    
    </div>

   
 </article>

</section>


     
 <section className='footer-modal'>

  <div style={{textAlign:'center',fontSize:'1rem',color:'white',fontWeight:'500'}}>Why wait any longer?</div>
  <div style={{textAlign:'center',fontSize:'2rem',color:'white',fontWeight:'500',width:''}} className='streamline'>Streamline your inventory management
and boost your business efficiency</div>
<button className='footer-register-btn' onClick={navigateToSignUp}>Register</button>



<section className='mobile-footer-link-container'>

  
<div className='mobile-link-content-container'>

<div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column',alignItems:"center",justifyContent:'center'}}>    
<div className='footer-logo' >ivy</div>
<div className='footer-statement' style={{textAlign:'center'}}>Ivy empowers small businesses and retailers to streamline their inventory processes, providing insights that enhance stock management.</div>
</div>
</div>




<div className='footer-link-container'>


<div className='mobile-link-content-container ' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Quick links</div>


<Link className='quick-links' to={'/about'}>About Us</Link>
<Link className='quick-links'  to={'/support'}>Support</Link>
<Link className='quick-links'  to={'/support/faq'}>FAQ</Link>
<Link className='quick-links'  to={'/referral'}>Refer & Win</Link>


  </div>


</div>
<div className='mobile-link-content-container quick-links-container' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Legal</div>

<Link className='quick-links' to={'/subscriptions'}>Pricing</Link>
<Link className='quick-links' to={'/terms-and-policy'}>Terms & Conditions</Link>
<Link className='quick-links' to={'/privacy-policy'}>Privacy Policy</Link>

  </div>


</div>





</div>

<section  style={{width:'100%',backgroundColor:'',display:'flex',justifyContent:"flex-start",alignItems:'center',height:'auto'}}>
<div className='last-link' style={{backgroundColor:''}}>

<div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

<div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'yellow',}} >Contact Us</div>
<div style={{marginTop:"0px",display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'white',fontSize:'1.0rem'}} >support@ivify.com.ng</div>




<section style={{width:'100%',}}>
<article className='footer-social-link-container'>
  <a href='https://mobile.x.com/ivy_encoded' className='social-link' target="_blank">
    <FaTwitter />
  </a>
 
  <a href='https://www.facebook.com/profile.php?id=61561915580549&name=xhp_nt__fb__action__open_user' className='social-link' target="_blank">
    <FaFacebook />
  </a>

  <a href='https://www.linkedin.com/company/ivifyng/' className='social-link' target="_blank">
    <FaLinkedin className='social-link' />
  </a>
</article>

</section>

</div>


</div>


</section>




















</section>








<section className='whole-footer-container'>

<article className='footer-link-container'>






<div className='link-content-container'>

<div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column',}}>    
<div className='footer-logo' >ivy</div>
<div className='footer-statement' >Ivy empowers small businesses and retailers to streamline their inventory processes, providing insights that enhance stock management</div>





</div>



</div>

<div className='link-content-container ' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Quick links</div>


<Link className='quick-links' to={'/about'}>About Us</Link>
<Link className='quick-links'  to={'/support'}>Support</Link>
<Link className='quick-links'  to={'/support/faq'}>FAQ</Link>
<Link className='quick-links' to={'/referral'}>Refer & Win</Link>


  </div>


</div>
<div className='link-content-container quick-links-container' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Legal</div>

<Link className='quick-links' to={'/subscriptions'}>Pricing</Link>
<Link className='quick-links' to={'/terms-and-policy'}>Terms & Conditions</Link>
<Link className='quick-links' to={'/privacy-policy'}>Privacy Policy</Link>

  </div>


</div>


<div className='link-content-container quick-links-container' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'yellow',}} >Contact Us</div>
<div style={{marginTop:"0px",display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'white',fontSize:'1.0rem'}} >Email: support@ivify.com.ng</div>




<section style={{width:'100%',}}>
<article className='footer-social-link-container'>
  <a href='https://mobile.x.com/ivy_encoded' className='social-link' target="_blank">
    <FaTwitter />
  </a>
 
  <a href='https://www.facebook.com/profile.php?id=61561915580549&name=xhp_nt__fb__action__open_user' className='social-link' target="_blank">
    <FaFacebook />
  </a>

  <a href='https://www.linkedin.com/company/ivifyng/' className='social-link' target="_blank">
    <FaLinkedin className='social-link' />
  </a>
</article>

</section>

  </div>


</div>


</article>
</section>



<div className='font-text copy-right-section'  > &copy; Copyright Encoded Technology  2024 . All Rights Reserved.</div>
</section>

    
    
    </>
  )
}














const file=[
  {
    id:1,
    src:"https://www.youtube.com/embed/JmQX1U71qRs?si=3P_hp4SGqzjzLmaE" 
  },
  {
    id:2,
    src:"https://www.youtube.com/embed/Re1tTsRvtRU?si=Fe56Qaw1znc4GTmO" 
  },
  {
    id:3,
    src:"https://www.youtube.com/embed/EYqIEBBjAAA?si=AqF4DVOqxBYM5No2" 
  },

  {
    id:4,
    src:"https://www.youtube.com/embed/MYlHnlwa-EU?si=hcbO0BkxBuudyvU2" 
  },
  
]



function App(){
  const {wakeServer}= useGlobal()
 
  const [count,setCount]=useState(1)
  const [isAsideHidden,setIsAsideHidden]=useState(true)
const navigate=useNavigate()



const navigateToSignUp=()=>{

  navigate('/sign-up')
}


const toggle=()=>{
  if (isAsideHidden){
    setIsAsideHidden(false)
  }

  else{
    setIsAsideHidden(true)
  }

}



const handleClick=(parameter)=>{

  if (parameter==='prev'){

  if (count===0){
    setCount(file.length-1)

    return
  }


    setCount(count-1)
  }

  else{
  if (count===file.length-1){
    setCount(0)
    return
  }


    setCount(count+1)
  }

}














  return(
    <>
<main className='home-page-container'>
<section className='home-first-section'>
  <div className='first-logo-container'>ivy</div>
  <div className='second-home-first-section'>
<Link className='home-link' to={'/subscriptions'}>Pricing</Link>
<Link className='home-link'to={'/log-in'} >Log In</Link>
<button className='home-btn' onClick={navigateToSignUp}>Sign up</button>


  </div>


</section>



<section  className={isAsideHidden?' hidden-top-nav':'mobile-top-nav'}>
<section className='mobile-home-first-section'>
  <div className='first-logo-container' onClick={()=>{
    if (!isAsideHidden){
      setIsAsideHidden(true)
    }
  }}>ivy</div>
  <div className='mobile-second-home-first-section'>



<div>
{
    isAsideHidden ? (
      <>
      <HiMenu fontSize={30} style={{marginLeft:'3vw',cursor:"pointer",color:'green',transition:'0.5s'}} onClick={toggle}></HiMenu>
      </>
    ):(
      <>
      <FaTimes  fontSize={30} style={{marginLeft:'3vw',color:'green',transition:'0.5s',cursor:'pointer'}} onClick={toggle} ></FaTimes>
      </>
    )
  }
</div>


  </div>


</section>

<section>
        <section className={`mobile-home-links ${!isAsideHidden ? 'show' : 'hide-mobile-home-links'}`}>
        
          <Link className='home-navigation-link' to={'/sign-up'}>Sign Up</Link>
          <Link className='home-navigation-link' to={'/log-in'}>Log In</Link>
          <Link className='home-navigation-link' to={'/subscriptions'}>Pricing</Link>
          <Link className='home-navigation-link' to={'support/faq'}>FAQs</Link>
          <Link className='home-navigation-link' to={'/about'}>About</Link>
        </section>
      </section>



</section>







<section className='home-modal'>

  <button className='home-modal-btn'>
  Join others and save hours daily
  </button>
<div className='modal-first-statement'>Boost your business efficiency with our <span className='modal-span'>comprehensive POS and inventory</span>    management software
</div>

<div className='modal-second-statement' >Accelerate Your Sales and Inventory Processes with Ease
</div>

<button className='modal-btn' onClick={navigateToSignUp}>Get started now</button>




 
<article className='home-modal-img-container'>
  <FaChevronLeft className='tutorial-nav-left-btn' onClick={() => handleClick('prev')} />

  <iframe
    width="100%"
    height="315"
    src={file[count].src}
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerPolicy="strict-origin-when-cross-origin"
    allowFullScreen
  ></iframe>

  <FaChevronRight onClick={() => handleClick('next')} className='tutorial-nav-right-btn' />
</article>












</section>







<section className='second-home-modal'>

<section className='refer-modal'>
  <div className='refer-modal-first-part'>
    <div className='refer-modal-pitch'>Earn  <span style={{color:'green'}}>&#x20A6;20,000 </span> bonus and more on our Referral Programme. </div>
    <div className='refer-modal-guide-container'>
      <strong className='refer-guide-heading'>
        <div className='refer-step-number'>1</div>
        Create an Account
      </strong>
      <div className='refer-step'>
        Go to our referral page and register with your email, phone number, and name to generate a unique referral coupon code.
      </div>
    </div>

    <div className='refer-modal-guide-container'>
      <strong className='refer-guide-heading'>
        <div className='refer-step-number'>2</div>
        Refer Someone
      </strong>
      <div className='refer-step'>
        Give your coupon code to a new subscriber for them to use when they subscribe.
      </div>
    </div>

    <div className='refer-modal-guide-container'>
      <strong className='refer-guide-heading'>
        <div className='refer-step-number'>3</div>
        Get Paid
      </strong>
      <div className='refer-step'>
        Our team will reach out to you once the person you referred subscribes, and you will get paid within 48 hours.
      </div>
    </div>




<button className='btn refer-btn' onClick={()=>navigate('/referall/sign-up')}>REGISTER NOW</button>


  </div>



  <div className='refer-modal-second-part'>

  </div>
</section>


<div className='second-home-heading'>Optimize Efficiency, Maximize Profitability</div>

  <div className='second-home-text' >Whatever your business needs, ivy empowers you to seamlessly manage your sales and inventory, tailored specifically for the Nigerian SME market. Gain comprehensive insights into your stock levels and sales trends, streamline your operations, and elevate your business efficiency and profitability.</div>



  

<section className='pitch-container'>
<article className='home-text-img-container'>
    <LazyLoadImage  className='home-text-img' src='pexels-tima-miroshnichenko-6169659.jpg'></LazyLoadImage>
    <div className='home-text-text'  style={{paddingLeft:'45px'}}>
      <div className='home-text-heading' >Sales Recording and Inventory Management</div>

      <p className='home-real-text'  > Experience seamless control over your inventory and sales operations with our meticulously crafted web-based software, meticulously tailored to cater to the unique needs of local SME shops. 
      </p>
      
      <p className='home-real-text' >Our intuitive platform empowers you to effortlessly navigate the complexities of inventory management and sales tracking. From the moment a user signs up and initiates a sale, our advanced system seamlessly records every transaction, meticulously updating your inventory in real-time by automatically deducting the quantity sold. </p>
   
    </div>

  </article>

  <article className='home-text-img-container'>
   
    <div className='home-text-text'  style={{paddingRight:'45px'}}>
      <div className='home-text-heading' >Daily Sales Tracking</div>

      <p className='home-real-text' >Unlock the power of data-driven insights and effortlessly track your daily sales performance with our innovative software solution. Seamlessly integrated into our platform is a dedicated page meticulously designed to empower users to delve into their sales metrics on a day-to-day basis.</p>

      <p className='home-real-text' >Our  intuitive feature not only simplifies the process of monitoring sales but also provides a comprehensive overview of performance trends over time. Armed with this invaluable data, users can uncover hidden patterns, identify emerging opportunities, and pinpoint areas for growth with precision. </p>
   
    </div>

    <LazyLoadImage  className='home-text-img' src='close-up-disabled-worker-with-protective-mask-immobilized-wheelchair-working-computer.jpg'></LazyLoadImage>

  </article>


  <article className='home-text-img-container'>
    <LazyLoadImage  className='home-text-img' src='pexels-tiger-lily-4483610.jpg'></LazyLoadImage>
    <div className='home-text-text' style={{paddingLeft:'45px'}}>
      <div className='home-text-heading' >Real-time Inventory Monitoring</div>

      <p  className='home-real-text' >Stay informed about your product availability at all times. With our software, users can conveniently check the quantity of each product left in their shop in real-time. Whether it's provisions, electronics, or clothing wears, monitor your inventory levels effortlessly.</p>
      <p  className='home-real-text'>Access historical inventory data to make informed decisions. Our software allows users to view past inventory quantities, including quantities for specific dates. Whether it's yesterday's stock levels or inventory data from months ago, gain valuable insights into your product availability over time.</p>
   
   
    </div>

  </article>





  <article className='home-text-img-container'>
   
   <div className='home-text-text' style={{paddingRight:'45px'}}>
     <div className='home-text-heading' >Profitability Analysis</div>

     <p className='home-real-text' >Harness the power of data to drive profitability. Our software enables users to analyze profitability by identifying the most profitable goods and accessing historical financial data. Dive deep into past financial records, track trends over time, and make data-driven decisions to optimize your business's financial performance.</p>

     <p   className='home-real-text'> Our software provides detailed information about each product, including sales trends, quantity changes, and profitability analysis. Empower yourself with the data you need to optimize your product offerings and maximize revenue.</p>
  
  
   </div>

   

   <LazyLoadImage  className='home-text-img' src='3543960.jpg'></LazyLoadImage>

 </article>


</section>



<section className='mobile-pitch-container'>
<article className='home-text-img-container'>
    <LazyLoadImage  className='home-text-img' src='pexels-tima-miroshnichenko-6169659.jpg'></LazyLoadImage>
    <div className='home-text-text'>
      <div className='home-text-heading' >Sales Recording and Inventory Management</div>

      <p className='home-real-text'  > Experience seamless control over your inventory and sales operations with our meticulously crafted web-based software, meticulously tailored to cater to the unique needs of local SME shops. 
      </p>
      
     
   
    </div>
  </article>




  <article className='home-text-img-container'>
  <LazyLoadImage  className='home-text-img' src='close-up-disabled-worker-with-protective-mask-immobilized-wheelchair-working-computer.jpg'></LazyLoadImage>
   
   <div className='home-text-text'>
     <div className='home-text-heading' >Daily Sales Tracking </div>

     <p className='home-real-text' >Unlock the power of data-driven insights and effortlessly track your daily sales performance with our innovative software solution. Seamlessly integrated into our platform is a dedicated page meticulously designed to empower users to delve into their sales metrics on a day-to-day basis.</p>

   
  
   </div>

  

 </article>


 <article className='home-text-img-container'>
    <LazyLoadImage  className='home-text-img' src='pexels-tiger-lily-4483610.jpg'></LazyLoadImage>
    <div className='home-text-text'>
      <div className='home-text-heading' >Real-time Inventory Monitoring</div>

      <p  className='home-real-text' >Stay informed about your product availability at all times. With our software, users can conveniently check the quantity of each product left in their shop in real-time. Whether it's provisions, electronics, or clothing wears, monitor your inventory levels effortlessly.</p>
      
   
   
    </div>

  </article>

  <article className='home-text-img-container'>
  <LazyLoadImage  className='home-text-img' src='3543960.jpg'></LazyLoadImage>
   
   <div className='home-text-text' >
     <div className='home-text-heading' >Profitability Analysis</div>

     <p className='home-real-text' >Harness the power of data to drive profitability. Our software enables users to analyze profitability by identifying the most profitable goods and accessing historical financial data. Dive deep into past financial records, track trends over time, and make data-driven decisions to optimize your business's financial performance.</p>

    
  
  
   </div>

   

 

 </article>




  
</section>




<section className='refer-modal'>
  <div className='refer-modal-first-part'>
    <div className='refer-modal-pitch'>Get   <span style={{color:'green'}}>7 DAYS</span> Free Trial on Register</div>
    <div className='refer-modal-guide-container'>
      <strong className='refer-guide-heading'>
        <div className='refer-step-number'>1</div>
        Create an Account
      </strong>
      <div className='refer-step'>
      Go to our registration page and sign up with your email, phone number, and name other informations to start your free trial.
      </div>
    </div>

    <div className='refer-modal-guide-container'>
      <strong className='refer-guide-heading'>
        <div className='refer-step-number'>2</div>
        Explore Features
      </strong>
      <div className='refer-step'>
      Discover all the powerful features of our inventory management software tailored for your business.
      </div>
    </div>

    <div className='refer-modal-guide-container'>
      <strong className='refer-guide-heading'>
        <div className='refer-step-number'>3</div>
        Request Extension
      </strong>
      <div className='refer-step'>
      If you need more time, contact our support team to request an additional 7-day extension.
      </div>
    </div>




<button className='btn refer-btn' onClick={()=>navigate('/sign-up')}>REGISTER NOW</button>


  </div>



  <div className='refer-modal-second-part registeration-part'>

  </div>
</section>









  

 <article className='brief-plan-info-container'>
  <div className='brief-partition text-partition'>
    <div><FaCheck  style={{color:'green'}} size={20}/> 2 Users</div>
    <div><FaCheck  style={{color:'green'}} size={20}/> 500 Sales Record/Month</div>
    
    <div><FaCheck  style={{color:'green'}} size={20}/> Receipt Generation</div>
    
  </div>
  <div className='brief-partition'>

    <strong className='brief-price-heading'>&#x20A6;3,225/m</strong>
    <div style={{textAlign:'',width:'100%',backgroundColor:''}}> Starting Price</div>
    <div style={{textAlign:'',width:'100%',backgroundColor:'',display:'',justifyContent:'',alignItems:''}}>

      <button className='check-plan-btn' onClick={()=>navigate('/subscriptions')}>Check all plans</button>  
    
    </div>
    
    </div>
    <div className='brief-partition text-partition'>
    <div className='check-an-holder'><FaCheck  style={{color:'green'}} size={20}/> Basic Business Analysis</div>
    <div className='check-an-holder'><FaCheck  style={{color:'green'}} size={20}/> Detail Product  Evaluation</div>
    <div className='check-an-holder'><FaCheck  style={{color:'green'}} size={20}/> Basic Finacial Analysis</div>
    
    </div>

   
 </article>


</section>


<section className='footer-modal'>

  <div style={{textAlign:'center',fontSize:'1rem',color:'white',fontWeight:'500'}}>Why wait any longer?</div>
  <div style={{textAlign:'center',fontSize:'2rem',color:'white',fontWeight:'500',width:''}} className='streamline'>Streamline your inventory management
and boost your business efficiency</div>
<button className='footer-register-btn' onClick={navigateToSignUp}>Register</button>



<section className='mobile-footer-link-container'>

  
<div className='mobile-link-content-container'>

<div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column',alignItems:"center",justifyContent:'center'}}>    
<div className='footer-logo' >ivy</div>
<div className='footer-statement' style={{textAlign:'center'}}>Ivy empowers small businesses and retailers to streamline their inventory processes, providing insights that enhance stock management.</div>
</div>
</div>




<div className='footer-link-container'>


<div className='mobile-link-content-container ' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Quick links</div>


<Link className='quick-links' to={'/about'}>About Us</Link>
<Link className='quick-links'  to={'/support'}>Support</Link>
<Link className='quick-links'  to={'/support/faq'}>FAQ</Link>
<Link className='quick-links' to={'/referral'}>Refer & Win</Link>


  </div>


</div>
<div className='mobile-link-content-container quick-links-container' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Legal</div>

<Link className='quick-links' to={'/subscriptions'}>Pricing</Link>
<Link className='quick-links' to={'/terms-and-policy'}>Terms & Conditions</Link>
<Link className='quick-links' to={'/privacy-policy'}>Privacy Policy</Link>

  </div>


</div>





</div>

<section  style={{width:'100%',backgroundColor:'',display:'flex',justifyContent:"flex-start",alignItems:'center',height:'auto'}}>
<div className='last-link' style={{backgroundColor:''}}>

<div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

<div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'yellow',}} >Contact Us</div>
<div style={{marginTop:"0px",display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'white',fontSize:'1.0rem'}} > support@ivify.com.ng</div>




<section style={{width:'100%',}}>
<article className='footer-social-link-container'>
  <a href='https://mobile.x.com/ivy_encoded' className='social-link' target="_blank">
    <FaTwitter />
  </a>
 
  <a href='https://www.facebook.com/profile.php?id=61561915580549&name=xhp_nt__fb__action__open_user' className='social-link' target="_blank">
    <FaFacebook />
  </a>

  <a href='https://www.linkedin.com/company/ivifyng/' className='social-link' target="_blank">
    <FaLinkedin className='social-link' />
  </a>
</article>

</section>

</div>


</div>


</section>




















</section>








<section className='whole-footer-container'>

<article className='footer-link-container'>






<div className='link-content-container'>

<div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column',}}>    
<div className='footer-logo' >ivy</div>
<div className='footer-statement' >Ivy empowers small businesses and retailers to streamline their inventory processes, providing insights that enhance stock management</div>





</div>



</div>

<div className='link-content-container ' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Quick links</div>
  <Link className='quick-links'  to={'/support'}>Support</Link>
  <Link className='quick-links'  to={'/support/faq'}>FAQ</Link>
<Link className='quick-links' to={'/about'}>About Us</Link>
<Link className='quick-links' to={'/referral'}>Refer & Win</Link>


  </div>


</div>
<div className='link-content-container quick-links-container' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{color:'yellow'}}>Legal</div>

<Link className='quick-links' to={'/subscriptions'}>Pricing</Link>
<Link className='quick-links' to={'/terms-and-policy'}>Terms & Conditions</Link>
<Link className='quick-links' to={'/privacy-policy'}>Privacy Policy</Link>

  </div>


</div>


<div className='link-content-container quick-links-container' style={{backgroundColor:''}}>

  <div style={{width:'100%',height:"100%",display:'flex',flexDirection:'column'}}>

  <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'yellow',}} >Contact Us</div>
<div style={{marginTop:"0px",display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',color:'white',fontSize:'1.0rem'}} >Email: support@ivify.com.ng</div>




<section style={{width:'100%',}}>
<article className='footer-social-link-container'>
  <a href='https://mobile.x.com/ivy_encoded' className='social-link' target="_blank">
    <FaTwitter />
  </a>
 
  <a href='https://www.facebook.com/profile.php?id=61561915580549&name=xhp_nt__fb__action__open_user' className='social-link' target="_blank">
    <FaFacebook />
  </a>

  <a href='https://www.linkedin.com/company/ivifyng/' className='social-link' target="_blank">
    <FaLinkedin className='social-link' />
  </a>
</article>

</section>

  </div>


</div>


</article>
</section>



<div className='font-text copy-right-section'  > &copy; Copyright Encoded Technology  2024 . All Rights Reserved.</div>
</section>


</main>
    </>
  )
}






export default App
// 