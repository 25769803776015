import React, { useState, useEffect,useRef, } from 'react'
import { useGlobal } from './context'
import axios from 'axios'
import { Link,  useNavigate,useLocation } from 'react-router-dom'
import { Preloader } from './preloader';
import { FaSearch ,FaPlus,FaMinus} from 'react-icons/fa';

import { Vortex } from 'react-loader-spinner';


import { Response } from './lay-out';

import { TopLink } from './subscription';
import { Pitch } from './App';



const account = [
 {
   id: 1,
   question: 'Can I sign up for more than one account?',
   answer: 'Yes, you can, but you will have to subscribe for each account separately.'
 },
 {
   id: 2,
   question: 'What does "sign up as owner" mean?',
   answer: 'Signing up as an owner means registering as the store owner. Ivy automatically designates the first user to sign up as the owner.'
 },
 {
   id: 12,
   question: 'What does "sign up as staff" mean?',
   answer: 'Signing up as staff means you are registering as a worker under a specific store. You do not have full privileges. You need to provide a store tag for the store, and you also need to wait for  approval before you can log in.'
 },
 {
   id: 3,
   question: 'How many users can sign up under a store?',
   answer: 'It depends on your subscription plan: two users for basic, three users for standard, and five users for premium. For outright subscriptions, there is an unlimited number of users.'
 },
 {
   id: 4,
   question: 'Can I sign up with more than one account using the same email or phone number?',
   answer: 'No, you cannot sign up for more than one account using the same email or phone number. Each phone number and email is unique to each account.'
 },
 {
   id: 5,
   question: 'What is a shop tag?',
   answer: 'A shop tag is a unique ID used to recognize your store. Any user who chooses to register under your store will have to provide the store tag to do so.'
 },
 {
   id: 6,
   question: 'Can I log in immediately after signing up as a staff?',
   answer: 'No, you need  approval to be able to log in.'
 },
 {
   id: 7,
   question: 'How can I change my store information?',
   answer: 'Kindly log in and go to the settings section. Click on store management, then click on "edit store information," and change the store details, whether it\'s name, contact number, or address.'
 },
 {
   id: 8,
   question: 'How can I check my store tag?',
   answer: 'Kindly log in and go to the settings section. Click on store management, then click on "edit store information," and you will see your store tag at the top of the page.'
 },
 {
   id: 9,
   question: 'Can I change my store tag?',
   answer: 'No, you cannot change your store tag.'
 },
 {
   id: 10,
   question: 'How can I change my password and other information?',
   answer: 'Kindly log in and check the sidebar. There is a section for the profile, kindly click on it and change your password or profile information.'
 },
 {
   id: 11,
   question: 'What if I forgot my password?',
   answer: 'Kindly click on "forgot password" on the login portal and input your email. A verification link will be sent to your email if it\'s registered under Ivy. Kindly click on the link and follow the prompt to reset your password.'
 }
];


const product = [
  {
    id: 1,
    question: 'How do I register a product into my inventory?',
    answer: 'Kindly log in and go to the "Register" section in the sidebar,then fill out the details.'
  },
  {
    id: 2,
    question: 'Can two products registered in the inventory have the same name or product ID (SKU)?',
    answer: 'No, two products cannot have the same name or product ID. Each name and product ID must be unique for each product.'
  },
  {
    id: 3,
    question: 'How many products can I register?',
    answer: 'For now, there is no maximum number of products you can register in the inventory.'
  },
  {
    id: 4,
    question: 'Can I register two or more products of the same brand?',
    answer: 'Yes, you can register two or more products of the same brand as long as each product has its unique name.'
  },
  {
    id: 5,
    question: 'Do I have to fill in all the information in the registration field?',
    answer: 'No, you only have to fill in information marked with an asterisk, indicating it is important.'
  },
  {
    id: 6,
    question: 'How do I add more quantity of a product on a new arrival?',
    answer: 'Kindly log in and go to the inventory section. Look for the product you want to update, click on the blue button under action, and follow the prompt.'
  },
  {
    id: 7,
    question: 'Can I delete or edit product information?',
    answer: 'Yes, you can delete or edit product information.'
  },
  {
    id: 8,
    question: 'How do I edit product information?',
    answer: 'Kindly log in and go to the inventory section. Click on store management, then click on the product, and click on the product management link at the top of the screen. You can edit the product name or price.'
  },
  {
    id: 9,
    question: 'How do I delete product information?',
    answer: 'Kindly log in and navigate to the inventory section. Locate the product you wish to delete, click on the red button under the action column, and follow the prompts. Note that this action requires appropriate user permissions.'
  }
];


const sales = [
 {
   id: 1,
   question: 'How do I record sales?',
   answer: 'Kindly log in and go to the "Sell Item" section in the sidebar. Click on "Add Item," then follow the prompt.'
 },
 {
   id: 2,
   question: 'How many sales records can I record within thirty days of subscription?',
   answer: 'It depends on your subscription plan: five hundred sales records for basic, eight hundred for standard, and one thousand two hundred for premium. For outright subscriptions, there is an unlimited number.'
 },
 {
   id: 3,
   question: 'Can I post more than one sales record at a time?',
   answer: 'Yes, Ivy is designed to handle recording more than one sale at a time to allow a seamless and fast user experience.'
 },
 {
   id: 4,
   question: 'Can I print out a receipt for sales I recorded?',
   answer: 'Yes, on Ivy you can print out a receipt. Ivy automatically enables this feature, but you can disable it by going to the settings section in the sidebar. Click on "Preferences Settings," then click on "General Preferences" and uncheck the "Enable Receipt Printing" field and save.  Note that this action requires appropriate user permissions'
 },
 {
   id: 5,
   question: 'How do I deactivate automatic recording of sales?',
   answer: 'Kindly log in and go to the settings section in the sidebar. Click on "Preferences Settings," then click on "Sales Preferences" and uncheck the "Allow Auto Price Filling" field and save. Kindly note that only admins can do this.'
 },
 {
   id: 6,
   question: 'What do "market is open" and "market is closed" mean under the sales record section?',
   answer: 'When the market is open, a user is free to record sales and delete a sale if eligible. When the market is closed, you cannot do this unless the the market is reopened.'
 },

];




export   const subscription = [
 {
   id: 1,
   question: 'How can I subscribe?',
   answer: 'On the home page, kindly click on the pricing button at the top link, and follow the prompt, or alternatively, you can log in and click on subscribe at the top nav, and follow the prompt. Either way, users have to be logged in before they can subscribe.'
 },
 {
   id: 2,
   question: 'What is a sales credit?',
   answer: 'A sales credit is the number of sales records you can post within thirty days of subscription. It depends on your subscription plan: five hundred sales credits for basic, eight hundred for standard, and one thousand two hundred for premium. For outright subscriptions, there is an unlimited number.'
 },
 {
   id: 3,
   question: 'What happens if my sales credit gets exhausted before my subscription expires?',
   answer: 'It means you have to subscribe to a new subscription to be able to record sales, although you can enjoy all other privileges attached to your subscription. You have unlimited access to record sales as an outright subscriber.'
 },
 {
   id: 4,
   question: 'If I have an unexpired subscription and I subscribe to a new one, what will happen?',
   answer: 'If your new subscription is higher than your present subscription, your new subscription expiry date will roll over the old one, and your sales credits will be summed together. But if the new subscription is lower than the one you have, each subscription will have its own expiry date, although the one that is highest will be active.'
 },
 {
   id: 5,
   question: 'If I subscribe for Outright subscription, who will handle the hosting of the overall app?',
   answer: 'The billing and charges for hosting the app are on the client and not us. You will be responsible for hosting and managing your server and database, including backup and recovery. However, we will guide and help you in the process.'
 }
];


const promotion = [
  {
    id: 1,
    question: 'How can I sign up as a referral?',
    answer: 'On the home page, go to the footer and click on the "Refer and Win" link, then follow the prompts.'
  },
  {
    id: 2,
    question: 'How much do I get as a referral?',
    answer: 'You  get twenty thousand  naira for each outright subscriber that uses your referral coupon code to subscribe.'
  },
  {
    id: 3,
    question: 'What is a coupon code?',
    answer: 'A coupon code is a unique code attributed to each user for signing up for our "Refer and Win" program. You need to give out this coupon code to a referred person to claim the incentive involved.'
  },
  {
    id: 4,
    question: 'Do I get a commission for referring users to subscribe to other subscription plans apart from outright?',
    answer: 'No, you don\'t get a commission for referring users to subscribe to other subscription plans for now. However, kindly check our website for any new promos and offers.'
  },
  {
    id: 5,
    question: 'Do I need to have an account to enjoy the referral incentive?',
    answer: 'No, you don\'t need to have an Ivy Inventory Management account, but you need to sign up as a referral, and a referral link will be sent to you. You can give this link to potential subscribers to use in signing up as an outright user.'
  },
  {
    id: 6,
    question: 'What are the payment processes?',
    answer: 'When someone signs up for an outright subscription to our app using your coupon code, we track that referral and attribute it to you as the referrer. We calculate the subscription fee paid by the new subscriber, process your referral reward, and automatically transfer it to your account.'
  },
  {
    id: 7,
    question: 'How will I know when I\'ve earned a referral reward?',
    answer: 'We\'ll notify you via email  whenever you earn a referral reward. The notification will include details about the reward amount and how it was calculated.'
  },
  {
    id: 8,
    question: 'Is there a limit to the number of referrals I can make?',
    answer: 'There is no limit to the number of referrals you can make. Feel free to refer as many friends or contacts as you like.'
  },
  {
    id: 9,
    question: 'Are there any restrictions on who I can refer?',
    answer: 'You can refer anyone who is interested in our services, but please ensure that you are following our referral program terms and conditions.'
  }
];






export const FAQ=()=>{

  const {ref}=useGlobal()

const [content,setContent]=useState(promotion)

const [indexArray,setIndexArray]=useState([])
const [searchInput,setSearchInput]=useState('')
const [isSubmitClicked,setIsSubmitClicked]=useState(false)


const navigate=useNavigate()




useEffect(()=>{

  if(ref){
    setContent(promotion)
  }

},[])








const handleIndex=(parameter,instance)=>{

if (instance==='add'){
 setIndexArray((prev)=>[...prev,parameter])
}

else{
 setIndexArray(indexArray.filter((item)=>item!==parameter))
}


}

const handleSearch = () => {
 if (searchInput !== '') {
   const allField = account.concat(sales, product,subscription,promotion);
   const lowerCaseSearchInput = searchInput.toLowerCase();
   const filteredInput = allField.filter((content) => 
     content.question.toLowerCase().includes(lowerCaseSearchInput) || 
     content.answer.toLowerCase().includes(lowerCaseSearchInput)
   );
   setContent(filteredInput);
   setIsSubmitClicked(true)
 }
};


useEffect(()=>{

 if (searchInput==='' && !ref){
  setContent(account)
  setIsSubmitClicked(false)
 }

},[searchInput])





 return(
  <>
  <TopLink></TopLink> 
  
 <main  className='support-whole-container'>
 <section className='support-image-container'>

<h1  className='support-pitch-header'>Frequently asked questions</h1>

<div type="text"  className='support-search-container'>
 <input type="text"   value={searchInput} onChange={(e)=>{setSearchInput(e.target.value);setIsSubmitClicked(false)}} className='support-search-input form-control'/>
 <button className='btn-primary btn support-search-btn' onClick={handleSearch}><FaSearch className='faq-search-btn-logo'></FaSearch></button>
</div>
</section>















{
 (isSubmitClicked & content.length===0) ? (
  <>
  
<section className='cant-find-container'>


<h1 className='cant-find-container-header'  style={{fontWeight:'600'}}>Cant find what you are looking for ?</h1>

<p  className='cant-find-container-pitch' style={{fontSize:'1.3rem',fontWeight:'600'}}> <strong style={{color:'green',cursor:'pointer'}}onClick={()=>navigate('/support')}>send us a message  </strong> and we will reply you shortly </p>
</section>
  </>
 ):(
  <>
  <section className='main-faq-container'>
    <div className='faq-subject-holder-scroll'>
    <article className='faq-subject-holder'>
  <h3 className='faq-subject-opener'>Table of Content  </h3>
  <div className='faq-subject-link' style={{color:content===account?'blue':"black"}} onClick={()=>{setContent(account);setIsSubmitClicked(false)}}>Account Registeration & Settings </div>
  <div className='faq-subject-link'  style={{color:content===product?'blue':"black"}}   onClick={()=>{setContent(product);setIsSubmitClicked(false)}}>Product Registeration</div>
  <div className='faq-subject-link' style={{color:content===sales?'blue':"black"}}   onClick={()=>{setContent(sales);setIsSubmitClicked(false)}}>Sales Record</div>

  <div className='faq-subject-link' style={{color:content===subscription?'blue':"black"}}   onClick={()=>{setContent(subscription);setIsSubmitClicked(false)}}>Subscription and billing</div>
  <div className='faq-subject-link' style={{color:content===promotion?'blue':"black"}}   onClick={()=>{setContent(promotion);setIsSubmitClicked(false)}}>Refer and win</div>


 </article>
    </div>
    <article className='faq-subject-holder no-show-holder'>
  <h3 className='faq-subject-opener'>Table of Content  </h3>
  <div className='faq-subject-link' style={{color:content===account?'blue':"black"}} onClick={()=>{setContent(account);setIsSubmitClicked(false)}}>Account Registeration & Settings </div>
  <div className='faq-subject-link'  style={{color:content===product?'blue':"black"}}   onClick={()=>{setContent(product);setIsSubmitClicked(false)}}>Product Registeration</div>
  <div className='faq-subject-link' style={{color:content===sales?'blue':"black"}}   onClick={()=>{setContent(sales);setIsSubmitClicked(false)}}>Sales Record</div>

  <div className='faq-subject-link' style={{color:content===subscription?'blue':"black"}}   onClick={()=>{setContent(subscription);setIsSubmitClicked(false)}}>Subscription and billing</div>
  <div className='faq-subject-link' style={{color:content===promotion?'blue':"black"}}   onClick={()=>{setContent(promotion);setIsSubmitClicked(false)}}>Refer and win</div>


 </article>

 <article className='faq-content-holder'>



  {
   content.map((content,index)=>{
    return(
     <>
     
     <div className='faq-question-symbol-container' key={index}>

<div className='symbol-container'>
{indexArray.includes(index) ?(
      <>
      <FaMinus className='faq-symbol' onClick={()=>handleIndex(index,'remove')}></FaMinus>
      </>
     ):(
      <>
      <FaPlus className='faq-symbol' onClick={()=>handleIndex(index,'add')}></FaPlus>
      </>
     )
     
    }


</div>

    


<div className='faq-question-answer-container' key={index}>
<div className='faq-question'>
{content.question} </div>

{
(indexArray.includes(index) || isSubmitClicked)   && (
<>
<div className='faq-answer'>  {content.answer} </div> 
</>
)
}

</div>

 
   
 
     </div>
     
     </>
    )
   })
  }
  
 

 </article>

</section>
  
  
  </>
 )
}




 <section style={{width:"100%",height:"auto"}}>
 <Pitch></Pitch>


</section>
 </main>
  
  </>
 )
}